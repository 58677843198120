 export const COLORS = {
  green: '#30836D',
  lightYellow: '#F8B464',
  marengo: '#6B6E71',
  grey: '#F2F2F2',
  inProgress : '#0E4EFF',
  open: '#00D2D3',
  closed:  '#ED5565',
  whiteLight: '#e9eaef',
  whiteSea: '#FBFBFF',
  white: '#FFFFFF',
  magenta: '#E95075',
  //login
  urbanLoginDark: '#0C0C0C',
  //colors Urban
  blackBackgroundFrom: '#121212',
  blackBackgroundTo: '#282828',
  urbanBlack: '#2E2E2E',
  urbanBaGLight: '#F1F1F1',
  artTherapy: '#9B1A9E',
  kids: '#41B9C3',
  youth: '#3764AA',
  teens: '#057DBE',
  eventLab: "#8CBE28",
  show: '#5ABB7F',
  blackLabel: '#282B30',
  darkUrban: '#15191E',
  premium: '#FFD700',
  //buttons
  buttonSecondaryBG: '#181818',
  buttonSecondaryBGActive: '#3A3A3A',
  buttonPrimaryBorder: '#383D46',
  //input
  inputBG: '#1E2227',
  //courses 
  courseCardBackground: '#1A1D22',
  teamBg: '#6B6E72',
  //loader
  loader: '#ededed',
  loaderSecondary: '#c3c3c3',
  woodSmoke: '#13161B',
  // opacity
  eventLabOpacity: '#DFF1B8',
  artTherapyOpacity: '#A66ABD',
  kidsOpacity: '#91EBF4',
  youthOpacity: '#899DEA',
} as const;

