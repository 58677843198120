import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { selectCurrentCourse } from '../../../store/selectors/courses';
import { changeActiveTab } from '../../../store/actions/appActions';
import CabinetVideoPreview from './CabinetVideoPreview/CabinetVideoPreview';
import CoursePlan from './CabinetVideoPreview/CoursePlan';
import CourseDescription from './CabinetVideoPreview/CourseDescription';
import CourseRecommended from './CabinetVideoPreview/CourseRecommended';
import { getCourseVideo } from '../../../utils/courses/getCourseVideo';
import CabinetNotSelected from './CabinetVideoPreview/CabinetNotSelected';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import { COURSES } from '../../../constants/courses';

import { CabinetWrapper, CabinetTopRow, CabinetBottomRow } from './styled';
import { selectUserProfile } from '../../../store/selectors/user';
import CabinetPrivate from './CabinetPrivate/CabinetPrivate';
import { getUserStartedCourses } from '../../../store/actions/userActions';

const Cabinet = () => {
   const currentCourse = useSelector(selectCurrentCourse);
   const dispatch = useDispatch();
   const courseVideoUrl = getCourseVideo(currentCourse);

   const { isMobile } = useMediaQuery();
   const userProfile = useSelector(selectUserProfile);
   const courseNotSelected = currentCourse === COURSES.prepareToSuccess ||
      currentCourse === COURSES.fiveDirections ||
      currentCourse === COURSES.relationshipGod ||
      currentCourse === COURSES.familyWithGod ||
      currentCourse === COURSES.creativeRestoration ||
      currentCourse === COURSES.bibleKidsLessons ||
      currentCourse === COURSES.innovationLeadershipStudio ||
      currentCourse === COURSES.basicsOfConsulting ||
      currentCourse === 'noSelected' ||
      courseVideoUrl === 'notSelected'

   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'cabinet' }))
      dispatch(getUserStartedCourses() as any)
   }, []);

   // should add new cases id books courses is added
   if (courseNotSelected && !userProfile) {
      return <CabinetNotSelected />
   } else if (courseNotSelected && userProfile) {
      return <CabinetPrivate />
   }

   return (
      <CabinetWrapper isMobile={isMobile}>
         <CabinetTopRow isMobile={isMobile}>
            <CabinetVideoPreview courseIFrameURL={courseVideoUrl} />
            <CoursePlan />
         </CabinetTopRow>
         <CabinetBottomRow isMobile={isMobile}>
            {!isMobile && <CourseDescription courseModification={currentCourse} />}
            {!isMobile && <CourseRecommended />}
         </CabinetBottomRow>
      </CabinetWrapper>
   );
};

export default Cabinet;