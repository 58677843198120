import { useState, useRef } from 'react';

import { Filter } from './Filter';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import roundArrowFilter from '../../../assets/global/roundArrowFilter.svg';

import { FiltersWrapper, ExpendedFilterWrapper, ArrowUp, ExpendedModal, ModalContent } from './styled';

export type OneFilter = {
   label: string,
   onClick: () => void,
   active?: boolean
}

type Props = {
   filters: OneFilter[]
}

const Filters = ({ filters }: Props) => {
   const [isExpendedFilters, setExpendedFilters] = useState(false);
   const { isMobile } = useMediaQuery();
   const ref = useRef<HTMLDivElement>(null);

   const handleExpendedFilters = () => {
      setExpendedFilters(!isExpendedFilters);
   }

   if (isMobile) {
      return <div ref={ref} className='lol'>
         <ExpendedFilterWrapper>
            {isExpendedFilters
               ? <ArrowUp src={roundArrowFilter} alt="" onClick={handleExpendedFilters} />
               : <img src={roundArrowFilter} alt="" onClick={handleExpendedFilters} />}
         </ExpendedFilterWrapper>
         {isExpendedFilters && <>
            <ExpendedModal  onClick={handleExpendedFilters}>
               <ModalContent left={ref?.current?.offsetLeft} top={ref?.current?.offsetTop} >
                  {filters.map(({ label, onClick, active }, index) => {
                     return <Filter active={active} key={index} text={label} onClick={onClick} />
                  })}
               </ModalContent>
            </ExpendedModal>
         </>}
      </div>
   }

   return (
      <FiltersWrapper>
         {filters.map(({ label, onClick, active }, index) => {
            return <Filter active={active} key={index} text={label} onClick={onClick} />
         })}
      </FiltersWrapper>
   );
};

export default Filters;