import { COURSES } from '../../constants/courses';

const prepareToSuccessConfig = [
   {
      name: 'Введение для тренеров',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/forLectors.pdf',
   }, {
      name: '1. Знакомство и введение в курс',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson1.pdf'
   }, {
      name: '2. Введение в поведенческую типологию DISC',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson2.pdf'
   }, {
      name: '3. Применение типологии DISC в повседневной жизни',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson3.pdf'
   }, {
      name: '4. Коммуникативная модель Шульца фон Туна',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson4.pdf'
   }, {
      name: '5. Понимание психологических отличий полов',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson5.pdf'
   }, {
      name: '6. Развитие навыка построения взаимодополняющих отношений',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson6.pdf'
   }, {
      name: '7. Введение в типологию личности по Майерс-Бриггс',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson7.pdf'
   }, {
      name: '8. Типология Майерс-Бриггс и профессия',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson8.pdf'
   }, {
      name: '9. Стиль обучения',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson9.pdf'
   }, {
      name: '10. Множественный интеллект',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson10.pdf'
   }, {
      name: '11. Стиль жизни, интересы и ценности',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson11.pdf'
   }, {
      name: '12. Соотношение между личными предпочтениями и профессией',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson12.pdf'
   }, {
      name: '13. Понимание своих чувств и эмоций',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson13.pdf'
   }, {
      name: '14. Управление своими чувствами и эмоциями',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson14.pdf'
   }, {
      name: '15. Выявление основных негативных установок',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson15.pdf'
   }, {
      name: '16. Понимание особенностей своего характера',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson16.pdf'
   }, {
      name: '17. Понимание своих ценностей',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson17.pdf'
   }, {
      name: '18. Выявление и оценка собственного мировоззрения',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson18.pdf'
   }, {
      name: '19. Формирование установки на работу над собой',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson19.pdf'
   }, {
      name: '20. Выбор нравственных ориентиров',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson20.pdf'
   }, {
      name: '21. Определение жизненного призвания',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson21.pdf'
   }, {
      name: '22. Развитие добродетельного характера как условие устойчивого успеха',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson22.pdf'
   }, {
      name: '23. Развитие навыков критического мышления',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson23.pdf'
   }, {
      name: '24. Развитие навыков творческого мышления',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson24.pdf'
   }, {
      name: '25. Развитие навыка анализа информации',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson25.pdf'
   }, {
      name: '26. Развитие навыка противостояния манипуляции',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson26.pdf'
   }, {
      name: '27. Развитие навыка принятия решений',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson27.pdf'
   }, {
      name: '28. Развитие навыка принятия решений',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson28.pdf'
   }, {
      name: '29. Развитие навыка управления стрессами и нагрузками',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson29.pdf'
   }, {
      name: '30. Развитие навыка управления финансами',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson30.pdf'
   }, {
      name: '31. Развитие навыков самопрезентации. Составление резюме',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson31.pdf'
   }, {
      name: '32. Развитие навыков самопрезентации. Собеседование при приеме на работу',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson32.pdf'
   }, {
      name: '33. По направлению к личностному росту',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/prepareToSuccess/lesson33.pdf'
   }]

export const relationshipGodConfig = [
   {
      name: 'Введение',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/introduction.pdf'
   },
   {
      name: '1: Подготовка к браку. Введение в курс.',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson1.pdf'
   },
   {
      name: '2: Миссия Бога и личное призвание.',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson2.pdf'
   },
   {
      name: '3: Богословие семьи и брака. Миссиональный подход.',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson3.pdf'
   },
   {
      name: '4: Любовь или влюбленность, как разобраться в чувствах?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson4.pdf'
   },
   {
      name: '5: Дружба с противоположным полом, стоит ли ее остерегаться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson5.pdf'
   },
   {
      name: '6: Мужчины и женщины, почему мы не всегда понимаем друг друга?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson6.pdf'
   },
   {
      name: '7: Установки, которые мешают созданию крепкой семьи.',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson7.pdf'
   },
   {
      name: '8: Расставания. Как правильно поставить точку в отношениях?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson8.pdf'
   },
   {
      name: '9: Выбор спутника жизни, на что обратить внимание?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson9.pdf'
   },
   {
      name: '10: Чистота отношений до брака, так ли она важна?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson10.pdf'
   },
   {
      name: '11: Свидания. Как развивать отношения?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson11.pdf'
   },
   {
      name: '12: Искусство ссориться, почему конфликт не всегда плохо?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/relationshipGod/lesson12.pdf'
   },
]

export const familyWithGodConfig = [
   {
      name: 'Введение',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/introduction.pdf'
   },
   {
      name: '1: Богословие семьи, отражённое в акте её создания в Бытие 1-2',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson1.pdf'
   },
   {
      name: '2: Богословие семьи, отражённое в акте её создания в Бытие 1-2',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson2.pdf'
   },
   {
      name: '3: «Кризисные» периоды в жизни семьи.',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson3.pdf'
   },
   {
      name: '4: Различия психологии мужчины и женщины. Что мужчины и женщины ожидают друг от друга в браке?',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson4.pdf'
   },
   {
      name: '5: Эмоциональный интеллект в семье. Как понимать и управлять эмоциями в семье?',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson5.pdf'
   },
   {
      name: '6: Как разрешать конфликты в семье?',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson6.pdf'
   },
   {
      name: '7: Роли и обязанности супругов в семье',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson7.pdf'
   },
   {
      name: '8: Интимная жизнь супругов.Как дарить/находить наслаждение в браке?',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson8.pdf'
   },
   {
      name: '9: Как заботиться о духовном здоровье семьи?',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson9.pdf'
   },
   {
      name: '10: Установление здоровых границ с расширенной семьей',
      file: 'https://s3.amazonaws.com/urbancenter.md/familyWithGod/lesson10.pdf'
   }
]

export const fiveDirectionsConfig = [
   {
      name: 'Введение: Курс «5D» – это путешествие подростков в мир под названием «жизнь»',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-1.pdf'
   },
   {
      name: '1: Привет, как дела?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-2.pdf',
      materials: 'https://drive.google.com/drive/folders/1q1hpxstPN6rycgWVooWOyK-mSeBUKFEl?usp=share_link'
   },
   {
      name: '2: Выжми максимум!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-3.pdf',
      materials: 'https://drive.google.com/drive/folders/1SXfTcAQ9Kj8RC7qX4QzYa_xOBlsoXalk?usp=share_link'
   },
   {
      name: '3: Внимание! – «Внимание»',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-4.pdf',
      materials: 'https://drive.google.com/drive/folders/1MTBdtlfqtMczsSpv4ZFPthtJIzpkDC9M?usp=share_link'
   },
   {
      name: '4: Быстро и качественно!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-5.pdf',
      materials: 'https://drive.google.com/drive/folders/11UYnqdXG-uH1anygPESrlbZSWO_-f0XZ?usp=share_link'
   },
   {
      name: '5: Нужда вокруг нас.',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-6.pdf',
      materials: 'https://drive.google.com/drive/folders/16T6faKeEGcooRHK71GhvL_QWGMJw0f8K?usp=share_link'
   },
   {
      name: '6: Ответь нужде!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-7.pdf',
      materials: 'https://drive.google.com/drive/folders/1gEvAc8suDrlndu7Dw829j0HyrZaKvsrh?usp=share_link'
   },
   {
      name: '7: Протяни руку помощи!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-8.pdf',
      materials: 'https://drive.google.com/drive/folders/1bjf46xywloKMMSARE6fk9sZdc2RZusSH?usp=share_link'
   },
   {
      name: '8: Целься в точку!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-9.pdf',
      materials: 'https://drive.google.com/drive/folders/1rKtzFvoIVnGcJq2OWWMsugENMp0bigOx?usp=share_link'
   },
   {
      name: '9: Настрой свой фокус!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-10.pdf',
      materials: 'https://drive.google.com/drive/folders/1tnqGJM_oTMH8uqOdYAHiQ4LM2NcrMG8S?usp=share_link'
   },
   {
      name: '10: В контакте с Богом',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-11.pdf',
      materials: 'https://drive.google.com/drive/folders/1GVS7FaDzvPRSm90b3aqnTZBb46pWQ-H3?usp=share_link'
   },
   {
      name: '11: С Днём рождения, Бог!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-12.pdf',
      materials: 'https://drive.google.com/drive/folders/1THn57EhoEAmmJH2VDa2C-ts4N1lxmFtE?usp=share_link'
   },
   {
      name: '12: Невозможное – возможно!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-13.pdf',
      materials: 'https://drive.google.com/drive/folders/1VQymRX9bETaiB6Ih9NeAZSaQyD4HJE7Q?usp=share_link'
   },
   {
      name: '13: Ты мне нужен!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-14.pdf',
      materials: 'https://drive.google.com/drive/folders/1RMCYw7FiSc133Fbk8O05RyJUo4NBTIww?usp=share_link'
   },
   {
      name: '14: Надо знать! (девушки)',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-15.pdf',
      materials: 'https://drive.google.com/drive/folders/1f3lO8OAGM_vODOcSeZ_dGVpFr5hUg3J3?usp=share_link'
   },
   {
      name: '14: Надо знать! (юноши)',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-16.pdf',
      materials: 'https://drive.google.com/drive/folders/1f3lO8OAGM_vODOcSeZ_dGVpFr5hUg3J3?usp=share_link'
   },
   {
      name: '15: Узнай себя!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-17.pdf',
      materials: 'https://drive.google.com/drive/folders/1q_8IFB6lB-zvr63Z3xseQGOuNvyAcvdu?usp=share_link'
   },
   {
      name: '16: Не нравится, но люблю!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-18.pdf',
      materials: 'https://drive.google.com/drive/folders/1Crh3qI7quRm-uwLPUK2MrtG8d_PJdnKu?usp=share_link'
   },
   {
      name: '17: Формула любви!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-19.pdf',
      materials: 'https://drive.google.com/drive/folders/1hEsicyt-oNlG_wU_Jf_1rTIb42YvtST_?usp=share_link'
   },
   {
      name: '18: Минус - в плюс!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-20.pdf',
      materials: 'https://drive.google.com/drive/folders/1SLVYShAXjvb8yoQ3pNwwr42-j8uvYdzV?usp=share_link'
   },
   {
      name: '19: Пока все дома!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-21.pdf',
      materials: 'https://drive.google.com/drive/folders/1f05XjrG3eIdmrlpRlNx1v5C4zsJTVqUH?usp=share_link'
   },
   {
      name: '20: Прими другого!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-22.pdf',
      materials: 'https://drive.google.com/drive/folders/1bV-Ztagl4QxBxVV8q6y-6a9x05yI6WJv?usp=share_link'
   },
   {
      name: '21: Мирись и не дерись!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-23.pdf',
      materials: 'https://drive.google.com/drive/folders/1yBGCo0SweIs21HoJVLfuyEbXxaV_yrCD?usp=share_link'
   },
   {
      name: '22: Остынь!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-24.pdf',
      materials: 'https://drive.google.com/drive/folders/1VW10DxXHnGMPaNL_VwOIQhcptqZz_i3L?usp=share_link'
   },
   {
      name: '23: Дружи с мылом!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-25.pdf',
      materials: 'https://drive.google.com/drive/folders/1efQ5tMmTxiNE6YaZL-0x5-yiD4IokXJd?usp=share_link'
   },
   {
      name: '24: Рецепт счастья',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-26.pdf',
      materials: 'https://drive.google.com/drive/folders/1iBW1KKcY6fGUVsfC-fMtTKP7kIkVpR2e?usp=share_link'
   },
   {
      name: '25: А что потом?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-28.pdf',
      materials: 'https://drive.google.com/drive/folders/1Z8tSA7oThSVpRo0unkn74vTrf-AvnTS-?usp=share_link'
   },
   {
      name: '26: «Сообщение свыше»',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-29.pdf',
      materials: 'https://drive.google.com/drive/folders/1HbIyUZ7pNCMtkCfXVWGZj8JWJ98VakHb?usp=share_link'
   },
   {
      name: '27: Пройти мимо',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-30.pdf',
      materials: 'https://drive.google.com/drive/folders/104-R5-6vdQJgupjuy7FzPRXQcRZT10GN?usp=share_link'
   },
   {
      name: '28: Что в моей ложке?',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-31.pdf',
      materials: 'https://drive.google.com/drive/folders/1akJgjs5o1jUzWeRUy7DrUIPpbwP3kOss?usp=share_link'
   },
   {
      name: '29: Приятного аппетита!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-32.pdf',
      materials: 'https://drive.google.com/drive/folders/1wvwPpae-OHglNMZRFXgtsuRWlQadTvTm?usp=share_link'
   },
   {
      name: '30: Вперёд и с песней!',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-33.pdf',
      materials: 'https://drive.google.com/drive/folders/1dsWDV7lOBjX5a5n2M2HwO4FGrTXP6M_0?usp=share_link'
   },
   {
      name: 'Ссылки',
      file: 'https://s3.amazonaws.com/urbancenter.md/KidsLessons/fiveDirections/fiveD-book-34.pdf'
   },
]

export const creativeRestorationConfig = [
   {
      name: 'Содержание и предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/Intro.pdf',
   },
   {
      name: '1: Я и другие',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson1/lesson1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson1/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Значимые люди',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson2/lesson2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson2/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Внутренний мир',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson3/lesson3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson3/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Уроки памяти',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson4/lesson4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson4/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Целостность',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson5/lesson5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson5/download/download.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Какой я?',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson6/lesson6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson6/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Прогулка по саду',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson7/lesson7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson7/download/cardHappy.pdf',
      ],
      materialsNames: [
         'Дерево.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '8: Плодоностное дерево',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson8/lesson8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson8/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Бог-Творец',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson9/lesson9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson9/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Грехопадение',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson10/lesson10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson10/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Мое сердце',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson11/lesson11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson11/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Выбор пути',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson12/lesson12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson12/download/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Искусство общения',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson13/lesson13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson13/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Ценности в отношениях',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson14/lesson14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson14/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Умение слушать',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson15/lesson15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson15/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Я и конфликты',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson16/lesson16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson16/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Я и мое окружение',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson17/lesson17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson17/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Взаимодействие с другими',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson18/lesson18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson18/download.pdf',
      ],
      materialsNames: [
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '19: Мой набор ценностей',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson19/lesson19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson19/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Ценность получать и отдавать',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson20/lesson20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson20/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Ценность моей   личности',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson21/lesson21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson21/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Ценность моей уникальности',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson22/lesson22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson22/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Ценность здорового образа жизни',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson23/lesson23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson23/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Ценность нашего тела',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson24/lesson24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson24/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Ценность моих эмоций',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson25/lesson25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson25/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Тайна эмоций',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson26/lesson26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson26/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Ценность времени',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson27/lesson27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson27/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Ценность моих талантов',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson28/lesson28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson28/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Кто мой ближний?',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson29/lesson29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson29/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Мои знания, опыт и мудрость',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson30/lesson30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson30/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Накануне Рождества',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson31/lesson31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/lesson31/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Рождественский спектакль',
      file: 'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/christmas/christmas.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/creativeRestoration/christmas/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   }
]

export const bibleKidsLessonsConfig = [
   {
      name: '1: Уроки 1-10',
      file: 'https://s3.amazonaws.com/urbancenter.md/bibleKidsLessons/lesson1.pdf',
   },
   {
      name: '2: Уроки 11-20',
      file: 'https://s3.amazonaws.com/urbancenter.md/bibleKidsLessons/lesson2.pdf',
   },
   {
      name: '3: Уроки 21-30',
      file: 'https://s3.amazonaws.com/urbancenter.md/bibleKidsLessons/lesson3.pdf',
   },
   {
      name: '4: Уроки 31-40',
      file: 'https://s3.amazonaws.com/urbancenter.md/bibleKidsLessons/lesson4.pdf',
   },
   {
      name: '5: Приложения',
      file: 'https://s3.amazonaws.com/urbancenter.md/bibleKidsLessons/lesson5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/bibleKidsLessons/download.zip',
      ],
      materialsNames: [
         'Приложения.zip',
      ],
      isForDownload: true,
   }
]

export const buckleUpConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/Intro.pdf',
   },
   {
      name: '1: Что значит учиться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson1/lesson1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson1/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Кто создал все вокруг меня?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson2/lesson2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson2/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Что я чувствую?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson3/lesson3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson3/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Что хорошо, а что плохо?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson4/lesson4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson4/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Что может мое тело?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson5/lesson5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson5/download.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Как стать здоровым и сильным?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson6/lesson6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson6/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Зачем нужна водичка?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson7/lesson7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson7/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Как я умею считать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson8/lesson8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson8/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Кто живет со мной в доме?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson9/lesson9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson9/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Как я могу помочь родителям?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson10/lesson10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson10/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Какие бывают времена года?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson11/lesson11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson11/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Из чего состоит неделя?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson12/lesson12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson12/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Как я могу стать сильным?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson13/lesson13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson13/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Кто такой врач?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson14/lesson14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson14/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Как живут муравьи?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson15/lesson15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson15/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Как я могу любить?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson16/lesson16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson16/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Что я люблю делать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson17/lesson17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson17/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Как работает автомобиль?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson18/lesson18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson18/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Чего я боюсь?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson19/lesson19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson19/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Какие есть приятные слова?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson20/lesson20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson20/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Какие бывают птицы?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson21/lesson21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson21/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Какие игрушки мои любимые?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson22/lesson22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson22/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Как создается музыка?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson23/lesson23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson23/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Почему брать чужое нельзя?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson24/lesson24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson24/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Какими бывают деревья и кусты?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson25/lesson25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson25/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Как себя вести в гостях?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson26/lesson26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson26/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Какие подарки я люблю?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson27/lesson27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson27/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Зачем нужно трудиться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson28/lesson28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson28/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Какими бывают эмоции?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson29/lesson29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson29/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Что такое эксперименты?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson30/lesson30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson30/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Как навести порядок в доме?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson31/lesson31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson31/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Зачем нужно быть послушным?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson32/lesson32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson32/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Какими бывают животные?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson33/lesson33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson33/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Какими бывают цифры и фигуры?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson34/lesson34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson34/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Как правильно дружить?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson35/lesson35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson35/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Почему не надо драться и ругаться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson36/lesson36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson36/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Как любить природу?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson37/lesson37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson37/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Какими могут быть вещи?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson38/lesson38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson38/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Какими бывают детки?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson39/lesson39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson39/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Что я умею делать лучше всего?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson40/lesson40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson40/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/Else.pdf',
      isForDownload: true,
   }
]

export const enterAltitudeConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/Intro.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/downloadIntro.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '1: Зачем нужно учиться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson1/lesson1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson1/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Откуда взялись люди?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson2/lesson2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson2/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Как сменяются времена года?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson3/lesson3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson3/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Зачем в неделе семь дней?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson4/lesson4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson4/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Что такое режим дня?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson5/lesson5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson5/download.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Как понять эмоции?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson6/lesson6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson6/lesson6.pdf',
      ],
      materialsNames: [
         'lesson6.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '7: Как вызвать улыбку словом?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson7/lesson7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson7/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Как я могу помочь?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson8/lesson8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson8/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Как оставаться здоровым?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson9/lesson9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson9/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Какие бывают стихийные бедствия?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson10/lesson10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson10/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Как живут народы мира?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson11/lesson11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson11/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Как себя ведут принцы и принцесы?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson12/lesson12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson12/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: О чем бы помечтать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson13/lesson13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson13/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Как мальчики и девочки могут дружить?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson14/lesson14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson14/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Как найти ответы на вопросы?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson15/lesson15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson15/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Можно ли все пересчитать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson16/lesson16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson16/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Когда в сердце живет доброта?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson17/lesson17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson17/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Сколько стоит молоко?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson18/lesson18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson18/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Кто такие предки?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson19/lesson19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson19/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Чем занимается пчела?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson20/lesson20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson20/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Нуждается ли природа в защите?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson21/lesson21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson21/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Что я за человек?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson22/lesson22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson22/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: От чего мне нужно защищаться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson23/lesson23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson23/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Как стать сильнее?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson24/lesson24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson24/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Кто такой патриот?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson25/lesson25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson25/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Зачем нужны домашние животные?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson26/lesson26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson26/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Который час?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson27/lesson27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson27/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Кем я хочу стать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson28/lesson28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson28/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Что такое гаджеты?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson29/lesson29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson29/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Откуда берется музыка?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson30/lesson30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson30/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Как любить свою семью?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson31/lesson31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson31/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Откуда появляется бабочка?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson32/lesson32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson32/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Чем занимается стоматолог?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson33/lesson33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson33/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Зачем человеку трудолюбие?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson34/lesson34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson34/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Как живут дельфины?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson35/lesson35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson35/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Как принимать гостей?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson36/lesson36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson36/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Что такое чувство вины?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson37/lesson37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson37/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Почему нельзя лгать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson38/lesson38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson38/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: За что я благодарен?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson39/lesson39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson39/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Что я узнал?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson40/lesson40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson40/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/Else.pdf',
      isForDownload: true,
   }
]

export const buckleUpConfigRo = [
   {
      name: 'Introducere',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/Intro.pdf',
   },
   {
      name: '1: Ce este învățarea',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson1/lesson1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson1/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Cine a creat toate lucrurile din jurul meu',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson2/lesson2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson2/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Ce simt',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson3/lesson3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson3/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Ce este bine si ce este rau',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson4/lesson4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson4/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Ce poate face corpul meu',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson5/lesson5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson5/download.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Cum sa devii sanatos si puternic',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson6/lesson6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson6/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: De ce avem nevoie de apa',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson7/lesson7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson7/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Cum să stiu sa numar',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson8/lesson8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson8/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Cine locuieste cu mine acasa',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson9/lesson9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson9/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Cum îmi pot ajuta parintii',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson10/lesson10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson10/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Care sunt anotimpurile anului',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson11/lesson11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson11/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Din ce se compune saptamana',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson12/lesson12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson12/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Cum pot deveni puternic',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson13/lesson13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson13/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Ce este un doctor',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson14/lesson14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson14/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Ce este un doctor',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson15/lesson15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson15/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Cum pot iubi',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson16/lesson16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson16/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Ce imi place sa fac',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson17/lesson17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson17/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Cum functioneaza un automobil',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson18/lesson18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson18/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: De ce ma tem',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson19/lesson19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson19/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Care cuvinte sunt frumoase',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson20/lesson20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson20/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Ce fel de pasari exista',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson21/lesson21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson21/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Care sunt jucariile mele preferate',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson22/lesson22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson22/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Ce este muzica',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson23/lesson23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson23/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: De ce nu ai voie sa iei bunul altuia',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson24/lesson24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson24/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Ce copaci si tufisuri exista',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson25/lesson25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson25/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Cum sa te comporti in vizita',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson26/lesson26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson26/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Ce cadouri îmi plac',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson27/lesson27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson27/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: De ce trebuie sa lucrezi',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson28/lesson28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson28/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Ce tipuri de emotii exista',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson29/lesson29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson29/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Ce sunt experimentele',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson30/lesson30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson30/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Cum sa fac curat in casa',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson31/lesson31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson31/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: De ce trebuie sa fii ascultator',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson32/lesson32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson32/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Ce fel de animale există',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson33/lesson33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson33/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Ce sunt cifrele si figurile geometrice',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson34/lesson34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson34/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Cum să-ți faci prieteni',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson35/lesson35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson35/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: De ce nu trebuie sa te certi si sa te bati',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson36/lesson36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson36/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Cum sa iubesc natura',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson37/lesson37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson37/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Ce tipuri de lucruri există',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson38/lesson38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson38/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Sunt toți copiii la fel',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson39/lesson39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson39/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: La ce ma pricep cel mai bine',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson40/lesson40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson40/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Alte manuale',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/Else.pdf',
      isForDownload: false,
   }
]
export const fiveDirectionsMaterialsConfig = [
   {
      name: '1: Введение',
      file: 'https://s3.amazonaws.com/urbancenter.md/fiveDirections/lesson1.pdf',
   }
]

export const buckeUpMaterialsConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/Intro.pdf',
   },
   {
      name: '1: Что значит учиться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson1/lessonq1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson1/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson1/lessonq1.pdf',
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '2: Кто создал все вокруг меня?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson2/lessonq2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson2/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson2/lessonq2.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '3: Что я чувствую?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson3/lessonq3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson3/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson3/lessonq3.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '4: Что хорошо, а что плохо?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson4/lessonq4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson4/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson4/lessonq4.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '5: Что может мое тело?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson5/lessonq5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson5/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson5/lessonq5.pdf'

      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '6: Как стать здоровым и сильным?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson6/lessonq6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson6/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson6/lessonq6.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '7: Зачем нужна водичка?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson7/lessonq7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson7/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson7/lessonq7.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '8: Как я умею считать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson8/lessonq8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson8/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson8/lessonq8.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '9: Кто живет со мной в доме?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson9/lessonq9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson9/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson9/lessonq9.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '10: Как я могу помочь родителям?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson10/lessonq10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson10/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson10/lessonq10.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '11: Какие бывают времена года?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson11/lessonq11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson11/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson11/lessonq11.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '12: Из чего состоит неделя?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson12/lessonq12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson12/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson12/lessonq12.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '13: Как я могу стать сильным?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson13/lessonq13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson13/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson13/lessonq13.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '14: Кто такой врач?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson14/lessonq14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson14/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson14/lessonq14.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '15: Как живут муравьи?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson15/lessonq15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson15/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson15/lessonq15.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '16: Как я могу любить?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson16/lessonq16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson16/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson16/lessonq16.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '17: Что я люблю делать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson17/lessonq17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson17/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson17/lessonq17.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '18: Как работает автомобиль?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson18/lessonq18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson18/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson18/lessonq18.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '19: Чего я боюсь?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson19/lessonq19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson19/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson19/lessonq19.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '20: Какие есть приятные слова?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson20/lessonq20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson20/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson20/lessonq20.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '21: Какие бывают птицы?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson21/lessonq21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson21/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson21/lessonq21.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '22: Какие игрушки мои любимые?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson22/lessonq22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson22/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson22/lessonq22.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '23: Как создается музыка?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson23/lessonq23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson23/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson23/lessonq23.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '24: Почему брать чужое нельзя?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson24/lessonq24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson24/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson24/lessonq24.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '25: Какими бывают деревья и кусты?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson25/lessonq25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson25/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson25/lessonq25.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '26: Как себя вести в гостях?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson26/lessonq26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson26/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson26/lessonq26.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '27: Какие подарки я люблю?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson27/lessonq27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson27/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson27/lessonq27.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '28: Зачем нужно трудиться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson28/lessonq28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson28/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson28/lessonq28.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '29: Какими бывают эмоции?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson29/lessonq29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson29/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson29/lessonq29.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '30: Что такое эксперименты?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson30/lessonq30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson30/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson30/lessonq30.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf',
      ],
      isForDownload: true,
   },
   {
      name: '31: Как навести порядок в доме?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson31/lessonq31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson31/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson31/lessonq31.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '32: Зачем нужно быть послушным?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson32/lessonq32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson32/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson32/lessonq32.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '33: Какими бывают животные?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson33/lessonq33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson33/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson33/lessonq33.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '34: Какими бывают цифры и фигуры?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson34/lessonq34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson34/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson34/lessonq34.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '35: Как правильно дружить?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson35/lessonq35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson35/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson35/lessonq35.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '36: Почему не надо драться и ругаться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson36/lessonq36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson36/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson36/lessonq36.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '37: Как любить природу?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson37/lessonq37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson37/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson37/lessonq37.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '38: Какими могут быть вещи?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson38/lessonq38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson38/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson38/lessonq38.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '39: Какими бывают детки?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson39/lessonq39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson39/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson39/lessonq39.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: '40: Что я умею делать лучше всего?',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson40/lessonq40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson40/downloadQ.zip',
         'https://s3.amazonaws.com/urbancenter.md/buckleUp/lesson40/lessonq40.pdf'
      ],
      materialsNames: [
         'Ресурсы.zip',
         'Ресурсы.pdf'
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUp/Else.pdf',
      isForDownload: true,
   }
]

export const buckeUpMaterialsConfigRo = [
   {
      name: 'Introducere',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/Intro.pdf',
   },
   {
      name: '1: Ce este învățarea',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson1/lessonq1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson1/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Cine a creat toate lucrurile din jurul meu',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson2/lessonq2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson2/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Ce simt',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson3/lessonq3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson3/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Ce este bine si ce este rau',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson4/lessonq4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson4/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Ce poate face corpul meu',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson5/lessonq5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson5/downloadQ.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Cum sa devii sanatos si puternic',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson6/lessonq6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson6/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: De ce avem nevoie de apa',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson7/lessonq7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson7/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Cum să stiu sa numar',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson8/lessonq8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson8/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Cine locuieste cu mine acasa',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson9/lessonq9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson9/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Cum îmi pot ajuta parintii',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson10/lessonq10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson10/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Care sunt anotimpurile anului',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson11/lessonq11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson11/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Din ce se compune saptamana',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson12/lessonq12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson12/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Cum pot deveni puternic',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson13/lessonq13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson13/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Ce este un doctor',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson14/lessonq14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson14/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Ce este un doctor',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson15/lessonq15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson15/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Cum pot iubi',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson16/lessonq16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson16/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Ce imi place sa fac',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson17/lessonq17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson17/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Cum functioneaza un automobil',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson18/lessonq18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson18/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: De ce ma tem',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson19/lessonq19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson19/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Care cuvinte sunt frumoase',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson20/lessonq20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson20/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Ce fel de pasari exista',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson21/lessonq21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson21/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Care sunt jucariile mele preferate',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson22/lessonq22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson22/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Ce este muzica',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson23/lessonq23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson23/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: De ce nu ai voie sa iei bunul altuia',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson24/lessonq24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson24/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Ce copaci si tufisuri exista',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson25/lessonq25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson25/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Cum sa te comporti in vizita',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson26/lessonq26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson26/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Ce cadouri îmi plac',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson27/lessonq27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson27/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: De ce trebuie sa lucrezi',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson28/lessonq28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson28/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Ce tipuri de emotii exista',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson29/lessonq29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson29/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Ce sunt experimentele',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson30/lessonq30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson30/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Cum sa fac curat in casa',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson31/lessonq31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson31/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: De ce trebuie sa fii ascultator',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson32/lessonq32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson32/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Ce fel de animale există',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson33/lessonq33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson33/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Ce sunt cifrele si figurile geometrice',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson34/lessonq34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson34/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Cum să-ți faci prieteni',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson35/lessonq35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson35/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: De ce nu trebuie sa te certi si sa te bati',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson36/lessonq36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson36/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Cum sa iubesc natura',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson37/lessonq37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson37/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Ce tipuri de lucruri există',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson38/lessonq38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson38/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Sunt toți copiii la fel',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson39/lessonq39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson39/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: La ce ma pricep cel mai bine',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson40/lessonq40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/lesson40/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Alte manuale',
      file: 'https://s3.amazonaws.com/urbancenter.md/buckleUpRo/Else.pdf',
      isForDownload: false,
   }
]


export const enterAltitudeMaterialsConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/Intro.pdf',
   },
   {
      name: '1: Зачем нужно учиться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson1/lessonq1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson1/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Откуда взялись люди?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson2/lessonq2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson2/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Как сменяются времена года?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson3/lessonq3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson3/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Что хорошо, а что плохо?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson4/lessonq4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson4/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Что такое режим дня?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson5/lessonq5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson5/downloadQ.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Как понять эмоции?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson6/lessonq6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson6/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Как вызывать улыбку словом?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson7/lessonq7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson7/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Как я могу помочь?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson8/lessonq8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson8/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Как оставаться здоровым?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson9/lessonq9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson9/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Какие бывают стихийные бедствия?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson10/lessonq10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson10/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Как живут народы мира?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson11/lessonq11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson11/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Из чего состоит неделя?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson12/lessonq12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson12/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: О чем бы помечтать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson13/lessonq13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson13/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Как мальчики и девочки могут дружить?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson14/lessonq14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson14/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Как найти ответы на вопросы?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson15/lessonq15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson15/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Можно ли все пересчитать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson16/lessonq16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson16/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Когда в сердце живет доброта?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson17/lessonq17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson17/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Сколько стоит молоко?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson18/lessonq18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson18/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Кто такие предки?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson19/lessonq19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson19/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Чем занимается пчела?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson20/lessonq20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson20/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Нуждается ли природа в защите?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson21/lessonq21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson21/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Что я за человек?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson22/lessonq22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson22/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: От чего мне нужно защищаться?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson23/lessonq23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson23/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Как стать сильнее?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson24/lessonq24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson24/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Кто такой патриот?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson25/lessonq25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson25/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Зачем нужны домашние животные?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson26/lessonq26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson26/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Который час?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson27/lessonq27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson27/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Кем я хочу стать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson28/lessonq28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson28/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Что такое гаджеты?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson29/lessonq29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson29/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Откуда берется музыка?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson30/lessonq30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson30/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Как навести порядок в доме?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson31/lessonq31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson31/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Откуда появляется бабочка?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson32/lessonq32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson32/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Чем занимается стоматолог?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson33/lessonq33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson33/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Зачем человеку трудолюбие?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson34/lessonq34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson34/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Как живут дельфины?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson35/lessonq35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson35/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Как принимать гостей?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson36/lessonq36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson36/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Что такое чувство вины?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson37/lessonq37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson37/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Почему нельзя лгать?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson38/lessonq38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson38/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: За что я благодарен?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson39/lessonq39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson39/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Что я узнал?',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson40/lessonq40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/enterAltitude/lesson40/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/enterAltitude/Else.pdf',
      isForDownload: true,
   }
]

export const innovationLeadershipStudioConfig = [
   {
      name: 'Уравнение отношений Я + Ты = Мы',
      file: 'https://s3.amazonaws.com/urbancenter.md/innovationLeadershipStudio/lesson1.pdf',
   },
   {
      name: 'Упражнения по ЭИ',
      file: 'https://s3.amazonaws.com/urbancenter.md/innovationLeadershipStudio/lesson2.pdf',
   }
]


export const basicsOfConsultingConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/basicsOfConsulting/intro.pdf',
   },
   {
      name: 'Определение цели и уровни консультирования',
      file: 'https://s3.amazonaws.com/urbancenter.md/basicsOfConsulting/lesson1.pdf',
   },
   {
      name: 'Консультант: его качества и умения',
      file: 'https://s3.amazonaws.com/urbancenter.md/basicsOfConsulting/lesson2.pdf',
   },
   {
      name: 'Клиент как личность: потребности и запросы',
      file: 'https://s3.amazonaws.com/urbancenter.md/basicsOfConsulting/lesson4.pdf',
   },
   {
      name: 'Процесс консультирования',
      file: 'https://s3.amazonaws.com/urbancenter.md/basicsOfConsulting/lesson5.pdf',
   },
   {
      name: 'Заключение',
      file: 'https://s3.amazonaws.com/urbancenter.md/basicsOfConsulting/outro.pdf',
   },
   {
      name: 'Библиография',
      file: 'https://s3.amazonaws.com/urbancenter.md/basicsOfConsulting/bibl.pdf',
   }
]

export const moveUpwardsConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/Intro.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/downloadIntro.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '1: Я люблю учиться',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson1/lesson1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson1/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Мои ощущения',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson2/lesson2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson2/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Мои близкие',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson3/lesson3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson3/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Мои старые и новые друзья',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson4/lesson4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson4/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Мои эмоции',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson5/lesson5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson5/download.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Их эмоции',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson6/lesson6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson6/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Мое здоровье',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson7/lesson7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson7/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Наша безопасность',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson8/lesson8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson8/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Я ценен',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson9/lesson9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson9/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Мы нужны',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson10/lesson10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson10/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Я запоминаю',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson11/lesson11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson11/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Мы логичны',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson12/lesson12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson12/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Я не одинок',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson13/lesson13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson13/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Мы поддержим',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson14/lesson14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson14/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Я талантлив',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson15/lesson15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson15/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Мы креативны',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson16/lesson16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson16/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Я и порядок ',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson17/lesson17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson17/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Наш дом',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson18/lesson18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson18/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Я люблю',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson19/lesson19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson19/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Мы любим',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson20/lesson20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson20/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Я понимаю',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson21/lesson21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson21/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Мы думаем',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson22/lesson22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson22/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Мое время придет',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson23/lesson23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson23/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Мы разные',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson24/lesson24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson24/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Моя песня',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson25/lesson25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson25/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Наш театр',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson26/lesson26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson26/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Я знаю свой пол',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson27/lesson27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson27/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Мое личное пространство',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson28/lesson28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson28/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Я умею прощать',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson29/lesson29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson29/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Мы поможем',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson30/lesson30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson30/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Я мечтаю',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson31/lesson31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson31/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Мы внимательны',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson32/lesson32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson32/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Мой лучший друг',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson33/lesson33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson33/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Наша лучшая команда',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson34/lesson34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson34/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Моя грусть и радость',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson35/lesson35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson35/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Наш ансамбль',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson36/lesson36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson36/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Мои деньги',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson37/lesson37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson37/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Животные вокруг нас',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson38/lesson38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson38/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Я под охраной',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson39/lesson39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson39/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Это наш день',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson40/lesson40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson40/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/Outro.pdf',
      isForDownload: true,
   }
]

export const moveUpwardsMaterialsConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/Intro.pdf',
   },
   {
      name: '1: Я люблю учиться',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson1/lessonq1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson1/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Мои ощущения',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson2/lessonq2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson2/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Мои близкие',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson3/lessonq3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson3/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Мои старые и новые друзья',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson4/lessonq4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson4/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Мои эмоции',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson5/lessonq5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson5/downloadQ.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Их эмоции',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson6/lessonq6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson6/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Мое здоровье',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson7/lessonq7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson7/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Наша безопасность',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson8/lessonq8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson8/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Я ценен',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson9/lessonq9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson9/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Мы нужны',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson10/lessonq10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson10/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Я запоминаю',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson11/lessonq11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson11/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Мы логичны',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson12/lessonq12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson12/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Я не одинок',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson13/lessonq13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson13/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Мы поддержим',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson14/lessonq14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson14/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Я талантлив',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson15/lessonq15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson15/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Мы креативны',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson16/lessonq16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson16/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Я и порядок',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson17/lessonq17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson17/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Наш дом',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson18/lessonq18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson18/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Я люблю',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson19/lessonq19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson19/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Мы любим',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson20/lessonq20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson20/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Я понимаю',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson21/lessonq21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson21/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Мы думаем',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson22/lessonq22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson22/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Мое время придет',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson23/lessonq23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson23/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Мы разные',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson24/lessonq24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson24/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Моя песня',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson25/lessonq25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson25/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Наш театр',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson26/lessonq26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson26/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Я знаю свой пол',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson27/lessonq27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson27/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Мое личное пространство',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson28/lessonq28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson28/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Я умею прощать',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson29/lessonq29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson29/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Мы поможем',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson30/lessonq30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson30/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Я мечтаю',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson31/lessonq31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson31/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Мы внимательны',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson32/lessonq32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson32/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Мой лучший друг',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson33/lessonq33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson33/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Наша лучшая команда',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson34/lessonq34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson34/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Моя грусть и радость',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson35/lessonq35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson35/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Наш ансамбль',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson36/lessonq36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson36/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Мои деньги',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson37/lessonq37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson37/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Животные вокруг нас',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson38/lessonq38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson38/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Я под охраной',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson39/lessonq39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson39/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Это наш день',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson40/lessonq40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/moveUpwards/lesson40/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/moveUpwards/Outro.pdf',
      isForDownload: true,
   }
]

export const chooseDirectionConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/Intro.pdf',
   },
   {
      name: '1: Я всесторонне развиваюсь',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson1/lesson1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson1/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Я забочусь о себе',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson2/lesson2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson2/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Я ощущаю',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson3/lesson3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson3/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Я учусь планировать',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson4/lesson4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson4/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Я помню',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson5/lesson5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson5/download.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Я – хороший друг',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson6/lesson6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson6/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Я верю',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson7/lesson7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson7/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Я разбираюсь',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson8/lesson8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson8/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Я ценю родителей',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson9/lesson9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson9/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Я и эмоции',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson10/lesson10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson10/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Я не беру чужое',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson11/lesson11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson11/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Я не завидую',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson12/lesson12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson12/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Я умею прощать',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson13/lesson13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson13/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Я умею просить прощения',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson14/lesson14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson14/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Я говорю правду',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson15/lesson15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson15/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Я внимателен',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson16/lesson16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson16/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Я трудолюбив',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson17/lesson17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson17/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Я талантлив',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson18/lesson18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson18/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Я целеустремлен',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson19/lesson19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson19/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Я развиваюсь',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson20/lesson20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson20/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Я могу играть',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson21/lesson21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson21/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Я делаю добро',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson22/lesson22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson22/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Я говорю',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson23/lesson23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson23/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Я и другие',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson24/lesson24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson24/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Я благодарен',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson25/lesson25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson25/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Я люблю',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson26/lesson26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson26/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Я провожу время с близкими',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson27/lesson27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson27/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Я умею делать подарки',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson28/lesson28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson28/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Я умею делать подарки',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson29/lesson29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson29/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Я в команде',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson30/lesson30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson30/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Я и мои экопривычки',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson31/lesson31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson31/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Я общаюсь без конфликтов',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson32/lesson32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson32/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Я умею выбирать',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson33/lesson33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson33/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Я побеждаю страхи',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson34/lesson34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson34/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Я мыслю логично',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson35/lesson35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson35/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Я – самостоятельный!',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson36/lesson36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson36/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Я защитник',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson37/lesson37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson37/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Я и гаджеты',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson38/lesson38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson38/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Я справляюсь с агрессией',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson39/lesson39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson39/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Я на каникулах',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson40/lesson40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson40/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/Outro.pdf',
      isForDownload: true,
   }
]

export const maintainBalanceConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/Intro.pdf',
   },
   {
      name: '1: Вместе мы сила',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson1/lesson1.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson1/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Мы талантливы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson2/lesson2.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson2/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Мы учимся',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson3/lesson3.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson3/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Мы дружим',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson4/lesson4.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson4/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Мы не берем чужое',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson5/lesson5.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson5/download.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Мы говорим правду',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson6/lesson6.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson6/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Мы умеем делиться',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson7/lesson7.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson7/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Мы успешные',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson8/lesson8.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson8/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Мы в виртуальном мире',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson9/lesson9.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson9/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Мы послушны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson10/lesson10.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson10/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Мы трудолюбивы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson11/lesson11.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson11/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Мы эмпатичны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson12/lesson12.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson12/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Мы любим читать',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson13/lesson13.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson13/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Мы умеем решать конфликты',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson14/lesson14.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson14/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Мы финансово грамотны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson15/lesson15.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson15/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Мы любим семью',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson16/lesson16.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson16/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Мы справляемся с гневом',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson17/lesson17.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson17/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Мы в ответе за свои действия',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson18/lesson18.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson18/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Мы социальны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson19/lesson19.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson19/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Мы не превозносимся',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson20/lesson20.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson20/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Мы терпеливы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson21/lesson21.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson21/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Мы мыслим позитивно',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson22/lesson22.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson22/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Мы отзывчивы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson23/lesson23.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson23/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Мы любимы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson24/lesson24.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson24/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Мы любим',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson25/lesson25.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson25/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Мы любознательны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson26/lesson26.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson26/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Мы организованны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson27/lesson27.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson27/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Мы и наша память',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson28/lesson28.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson28/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Мы в реальном мире',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson29/lesson29.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson29/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Мы уважаем',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson30/lesson30.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson30/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Мы не смотрим порно',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson31/lesson31.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson31/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Мы в ответе за свои слова',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson32/lesson32.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson32/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Мы и наши ценности',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson33/lesson33.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson33/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Мы развиваемся',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson34/lesson34.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson34/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Мы воплощаем мечты',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson35/lesson35.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson35/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Мы внимательные',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson36/lesson36.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson36/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Мы решительные',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson37/lesson37.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson37/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Мы креативные',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson38/lesson38.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson38/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Мы принимаем решения',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson39/lesson39.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson39/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Мы инициативны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson40/lesson40.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson40/download.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/outro.pdf',
      isForDownload: true,
   }
]

export const chooseDirectionMaterialsConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/Intro.pdf',
   },
   {
      name: '1: Я всесторонне развиваюсь',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson1/lesson1Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson1/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Я забочусь о себе',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson2/lesson2Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson2/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Я ощущаю',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson3/lesson3Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson3/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Я учусь планировать',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson4/lesson4Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson4/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Я помню',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson5/lesson5Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson5/downloadQ.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Я – хороший друг',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson6/lesson6Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson6/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Я верю',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson7/lesson7Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson7/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Я разбираюсь',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson8/lesson8Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson8/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Я ценю родителей',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson9/lesson9Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson9/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Я и эмоции',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson10/lesson10Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson10/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Я не беру чужое',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson11/lesson11Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson11/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Я не завидую',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson12/lesson12Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson12/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Я умею прощать',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson13/lesson13Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson13/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Я умею просить прощения',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson14/lesson14Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson14/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Я говорю правду',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson15/lesson15Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson15/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Я внимателен',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson16/lesson16Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson16/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Я трудолюбив',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson17/lesson17Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson17/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Я талантлив',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson18/lesson18Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson18/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Я целеустремлен',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson19/lesson19Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson19/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Я развиваюсь',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson20/lesson20Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson20/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Я могу играть',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson21/lesson21Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson21/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Я делаю добро',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson22/lesson22Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson22/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Я говорю',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson23/lesson23Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson23/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Я и другие',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson24/lesson24Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson24/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Я благодарен',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson25/lesson25Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson25/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Я люблю',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson26/lesson26Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson26/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Я провожу время с близкими',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson27/lesson27Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson27/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Я умею делать подарки',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson28/lesson28Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson28/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Я умею делать подарки',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson29/lesson29Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson29/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Я в команде',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson30/lesson30Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson30/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Я и мои экопривычки',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson31/lesson31Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson31/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Я общаюсь без конфликтов',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson32/lesson32Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson32/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Я умею выбирать',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson33/lesson33Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson33/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Я побеждаю страхи',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson34/lesson34Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson34/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Я мыслю логично',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson35/lesson35Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson35/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Я – самостоятельный!',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson36/lesson36Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson36/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Я защитник',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson37/lesson37Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson37/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Животные вокруг нас',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson38/lesson38Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson38/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Я справляюсь с агрессией',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson39/lesson39Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson39/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Я на каникулах',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson40/lesson40Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/chooseDirection/lesson40/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/chooseDirection/outro.pdf',
      isForDownload: true,
   }
]


export const maintainBalanceMaterialsConfig = [
   {
      name: 'Предисловие',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/Intro.pdf',
   },
   {
      name: '1: Вместе мы сила',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson1/lesson1Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson1/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '2: Мы талантливы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson2/lesson2Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson2/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '3: Мы учимся',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson3/lesson3Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson3/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '4: Мы дружим',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson4/lesson4Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson4/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '5: Мы не берем чужое',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson5/lesson5Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson5/downloadQ.zip',

      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '6: Мы говорим правду',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson6/lesson6Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson6/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '7: Мы умеем делиться',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson7/lesson7Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson7/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '8: Мы успешные',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson8/lesson8Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson8/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '9: Мы в виртуальном мире',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson9/lesson9Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson9/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '10: Мы послушны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson10/lesson10Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson10/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '11: Мы трудолюбивы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson11/lesson11Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson11/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '12: Мы эмпатичны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson12/lesson12Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson12/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '13: Мы любим читать',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson13/lesson13Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson13/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '14: Мы умеем решать конфликты',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson14/lesson14Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson14/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '15: Мы финансово грамотны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson15/lesson15Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson15/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '16: Мы любим семью',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson16/lesson16Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson16/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '17: Мы справляемся с гневом',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson17/lesson17Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson17/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '18: Мы в ответе за свои действия',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson18/lesson18Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson18/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '19: Мы социальны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson19/lesson19Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson19/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '20: Мы не превозносимся',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson20/lesson20Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson20/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '21: Мы терпеливы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson21/lesson21Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson21/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '22: Мы мыслим позитивно',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson22/lesson22Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson22/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '23: Мы отзывчивы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson23/lesson23Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson23/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '24: Мы любимы',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson24/lesson24Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson24/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '25: Мы любим',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson25/lesson25Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson25/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '26: Мы любознательны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson26/lesson26Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson26/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '27: Мы организованны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson27/lesson27Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson27/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '28: Мы и наша память',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson28/lesson28Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson28/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '29: Мы в реальном мире',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson29/lesson29Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson29/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '30: Мы уважаем',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson30/lesson30Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson30/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '31: Мы не смотрим порно',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson31/lesson31Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson31/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '32: Мы в ответе за свои слова',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson32/lesson32Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson32/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '33: Мы и наши ценности',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson33/lesson33Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson33/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '34: Мы развиваемся',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson34/lesson34Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson34/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '35: Мы воплощаем мечты',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson35/lesson35Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson35/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '36: Мы внимательные',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson36/lesson36Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson36/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '37: Мы решительные',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson37/lesson37Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson37/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '38: Мы креативные',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson38/lesson38Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson38/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '39: Мы принимаем решения',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson39/lesson39Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson39/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: '40: Мы инициативны',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson40/lesson40Q.pdf',
      materials: [
         'https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson40/downloadQ.zip',
      ],
      materialsNames: [
         'Ресурсы.zip',
      ],
      isForDownload: true,
   },
   {
      name: 'Новые учебники',
      file: 'https://s3.amazonaws.com/urbancenter.md/maintainBalance/outro.pdf',
      isForDownload: true,
   }
]

export const getCourseBookConfig = (courseModification: string, materialsModification?: string, landuage?: 'ro' | 'ru' | 'en'): {
   name: string;
   file: string;
   materials?: string | string[];
   materialsNames?: string[];
   isForDownload?: boolean;
}[] => {
   switch (courseModification) {
      case COURSES.prepareToSuccess:
         return prepareToSuccessConfig;
      case COURSES.relationshipGod:
         return relationshipGodConfig;
      case COURSES.familyWithGod:
         return familyWithGodConfig;
      case COURSES.fiveDirections:
         return fiveDirectionsConfig;
      case COURSES.creativeRestoration:
         return creativeRestorationConfig;
      case COURSES.bibleKidsLessons:
         return bibleKidsLessonsConfig;
      case COURSES.innovationLeadershipStudio:
         return innovationLeadershipStudioConfig;
      case COURSES.basicsOfConsulting:
         return basicsOfConsultingConfig;
      case COURSES.maintainBalance:
         return maintainBalanceConfig;
      case COURSES.buckleUp:
         if (landuage === 'ro') {
            return buckleUpConfigRo;
         }
         return buckleUpConfig;
      case COURSES.enterAltitude:
         if (landuage === 'ro') {
            return enterAltitudeConfig;
         }
         return enterAltitudeConfig;
      case COURSES.moveUpwards:
         if (landuage === 'ro') {
            return moveUpwardsConfig;
         }
         return moveUpwardsConfig;
      case COURSES.chooseDirection:
         return chooseDirectionConfig;
      // TODO add real configs later
      case COURSES.onlyMaterials:
         if (materialsModification === COURSES.buckleUp) {
            if (landuage === 'ro') {
               return buckeUpMaterialsConfigRo;
            }
            return buckeUpMaterialsConfig;
         }
         if (materialsModification === COURSES.enterAltitude) {
            return enterAltitudeMaterialsConfig;
         }
         if (materialsModification === COURSES.moveUpwards) {
            return moveUpwardsMaterialsConfig;
         }
         if (materialsModification === COURSES.chooseDirection) {
            return chooseDirectionMaterialsConfig;
         }
         if (materialsModification === COURSES.maintainBalance) {
            return maintainBalanceMaterialsConfig;
         }
         if (materialsModification === COURSES.discoverHorizons) {
            return fiveDirectionsMaterialsConfig;
         }
         return [];
      default:
         return [];
   }
}
