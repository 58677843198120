import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import maib from '../../../assets/global/maib.png';

import { selectUserPaymentStatus, selectUserProfile } from '../../../store/selectors/user';

import {
   LastStepWrapper,
   FirstStepHolderForm,
   FormTitle,
   WaitingTitle,
   WaitingSubtitle
} from '../styled'
import { checkPaymentStatus } from '../../../store/actions/userActions';
import { CircularProgressWithLabel } from './SecondStep';


const SecondStepModal = ({ handleNextSecondStep, course_to_update }: {
   handleNextSecondStep: any;
   course_to_update: any;
}) => {
   const dispatch = useDispatch();
   const [time, setTime] = useState(10 * 60);
   const [percentage, setPercentage] = useState(100);
   const transactionId = localStorage.getItem('transactionId') as string;

   const user = useSelector(selectUserProfile);
   const paymentStatus = useSelector(selectUserPaymentStatus);

   const handleCheckPaymentStatus = () => {
      dispatch(checkPaymentStatus({
         transaction_id: transactionId,
         userId: user?.id as string,
         premium_courses: user?.premium_courses as string[],
         course_to_update
      }) as any)
   }
   
   if (paymentStatus === 'payed' || paymentStatus === 'error') {
      handleNextSecondStep();
   }

   useEffect(() => {
      const interval = setInterval(() => {
         handleCheckPaymentStatus();
         if (paymentStatus === 'payed' || paymentStatus === 'error') {
            handleNextSecondStep();
         }
      }, 10000);

      return () => clearInterval(interval);
   }, []);


   useEffect(() => {
      const interval = setInterval(() => {
         setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
         setPercentage((prevPercentage) => (prevPercentage > 0 ? prevPercentage - 0.1 : 0));
      }, 1000);

      return () => clearInterval(interval);
   }, []);

   const minutes = Math.floor(time / 60);
   const seconds = time % 60;

   return <FirstStepHolderForm>
      <FormTitle >
         <p>Ожидание оплаты</p>

         <div className='powered'>
            <div className='powered-text'>Powered by  </div> <img src={maib} alt="" />
         </div>
      </FormTitle>
      <LastStepWrapper>
         <CircularProgressWithLabel value={percentage} minutes={minutes} seconds={seconds} />
      </LastStepWrapper>
      <WaitingTitle>
         Ожидание оплаты, подождите пока завершиться оплата
      </WaitingTitle>
      <WaitingSubtitle>
         Ваша оплата 800 MDL для Urban


      </WaitingSubtitle>
   </FirstStepHolderForm>
};

export default SecondStepModal;