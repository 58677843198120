
import { RecommendedWrapper, RecommendedSection } from './styled';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import BuckleUpProgressio from './Recommended/BuckleUpProgressio';
import EnterAltitudeProgressio from './Recommended/EnterAltitudeProgressio';
import MoveUpwardsProgressio from './Recommended/MoveUpwardsProgressio';
import DirectionProgressio from './Recommended/DirectionProgressio';
import BallanceProgressio from './Recommended/BallanceProgressio';

const RecommendedCourses = () => {
   const { width } = useMediaQuery();

   return (
      <RecommendedSection>
         <h1>Progressio</h1>
         <RecommendedWrapper>
            <BuckleUpProgressio />
            {700 < width && <EnterAltitudeProgressio />}
            {1200 < width && <MoveUpwardsProgressio />}
            {1500 < width && <DirectionProgressio />}
            {1800 < width && <BallanceProgressio />}
         </RecommendedWrapper>
      </RecommendedSection>
   );
};

export default RecommendedCourses;