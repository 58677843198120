import { useSelector } from 'react-redux';

import Header from '../Header/Header';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';
import TabsContainer from '../Tabs/TabsContainer';
import { selectLoginPage, selectAdminPage } from '../../store/selectors/app';

const HomePage = () => {
   const isLoginPageOpen = useSelector(selectLoginPage);
   const isAdminPageOpen = useSelector(selectAdminPage);

   if (isLoginPageOpen || isAdminPageOpen) {
      return null;
   }

   return (
      <div>
         <Header />
         <NavigationMenu />
         <TabsContainer />
      </div>
   );
};

export default HomePage;