import styled from "styled-components";
import { COLORS } from '../../../constants/colors';

export const LoginPageContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin: 0 auto;
   background-color: ${COLORS.urbanLoginDark};
`;

export const LoginLeftSide = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   width: ${({ isMobile }) => isMobile ? '100%' : '40%'};
   background-color: ${COLORS.urbanLoginDark};
   padding: 40px;
   height: 100vh;
   overflow-y: scroll;

   .logo {
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: ${({ isMobile }) => isMobile ? '10px' : '80px'};;
   }

`;

export const LoginRightSide = styled.div`
   display: flex;
   flex-direction: column;
   width: 60%;
   height: 100vh;
   img {
      height: 100vh;
   }
`;

export const LoginTitle = styled.h1<{ isMobile: boolean }>`
   font-size: ${({isMobile}) => isMobile ? '20px' :'40px'} !important;
   color: ${COLORS.white};
   margin-bottom: 20px;
`;

export const HolderForm = styled.form`
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   button {
      width: 100% !important;
   }

   input {
      margin-bottom: 20px;
   }

`;

export const InputTitle = styled.div`
   margin-top: 10px;
   margin-bottom: 10px;
   color: ${COLORS.white};
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
`;

export const InputSubtitle = styled.div`
   margin-bottom: 50px;
   color: ${COLORS.white};
   opacity: 0.5;
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
   text-decoration: underline;
   max-width: 150px;

   &:hover {
      cursor: pointer;
   }
`;

export const RegisterSubtitle = styled.div`
   color: ${COLORS.white};
   font-weight: 400;
   font-size: 14px;
   max-width: 450px;

   &:hover {
      cursor: pointer;
   }
`;

export const VerticalSeparator = styled.br`
   height: 15px;
`;

export const LogoRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   .close {
      width: 10px;
      height: 10px;
      opacity: 0.5;
      margin-top: 30px;

      &:hover {
         cursor: pointer;
         opacity: 1;
      }
   }
`;

export const RowInput = styled.div<{ width: number }>`
   display: flex;
   gap: 20px;

   input {
      max-width: 220px;
   }
`