import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';

import { CursesSections } from 'types/common';

import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import { selectCurrentConfig, selectCurrentPage } from '../../../store/selectors/book';
import { setCurrentPage, setLeftMenu } from '../../../store/actions/bookActions';
import bookImage from '../../../assets/global/bookImage.svg';

import { selectCurrentCourse, selectAllCourses } from '../../../store/selectors/courses';
import { getCourseColor } from '../../../utils/courses/getCourseColor';
import { Button } from '../../UI/Button/index';

import { BookPlanHolder, BookPlanTitle, OneDescriptionElementWrapper, BookPlanWrapper, Divider, BookDownload } from './styled';
import { downloadMaterials } from '../../../utils/courses/downloadMaterials';


const BookPlan = () => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();
   const currentPage = useSelector(selectCurrentPage)
   const dispatch = useDispatch();
   const currentConfig = useSelector(selectCurrentConfig)
   const [isDownloading, setIsDownloading] = useState(false);
   const [isDownloadingDone, setIsDownloadDone] = useState(false);

   const handleChangePage = (page: number) => {
      dispatch(setCurrentPage({ currentPage: page }))
      dispatch(setLeftMenu({ isLeftMenuOpen: false }))
   }

   const materials = currentConfig[currentPage].materials;
   const materialsNames = currentConfig[currentPage].materialsNames;
   const isForDownload = currentConfig[currentPage].isForDownload;

   //Current course color
   const currentCourse = useSelector(selectCurrentCourse);
   const courses = useSelector(selectAllCourses);
   const course = courses.find((i) => i.courseModification === currentCourse)
   const currentCourseSection = course?.section;
   const courseColor = getCourseColor(currentCourseSection as CursesSections)

   const oneProgramElement = (i: { name: string, file?: string }, index: number) => {
      return <>
         <OneDescriptionElementWrapper
            color={courseColor}
            isMobile={isMobile}
            isCurrent={currentPage === index}
            onClick={() => handleChangePage(index)}
            key={i.name}
         >
            {t(i.name)}
         </OneDescriptionElementWrapper>
      </>
   }

   const handleMaterialsClick = () => {
      if (isForDownload && Array.isArray(materials) && Array.isArray(materialsNames)) {
         setIsDownloading(true);
         return downloadMaterials(materials, materialsNames, () => {
            setIsDownloading(false)
            setIsDownloadDone(true)
         })
      }
      if (!Array.isArray(materials)) {
         return window.open(materials, '_blank')
      }
   }

   const renderDownloadButton = () => {
      if (isDownloading) {
         return <CircularProgress size={20} color='secondary' />
      }
      if (isDownloadingDone) {
         return <DownloadDoneIcon fontSize='small' />
      } else {
         return <FileDownloadIcon fontSize='small' />
      }
   }         

   return (
      <BookPlanHolder isMobile={isMobile}>
         <BookPlanTitle isMobile={isMobile}>
            <h1>
               {t('cabinet.courseProgram')}
            </h1>
            <div className='chapters'>
               <img src={bookImage} alt="" />  {currentConfig.length} лекций
            </div>
         </BookPlanTitle>
         <Divider isMobile={isMobile} />
         <BookPlanWrapper isMaterials={!!materials} isMobile={isMobile}>
            {currentConfig.map((i, index) => {
               return oneProgramElement(i, index)
            })}
         </BookPlanWrapper>
         {materials && <>
            <Divider isMobile={isMobile} />
            <BookDownload isMobile={isMobile}>
               <h1>
                  {t('cabinet.downloadCourse')}
               </h1>
               <Button
                  modifier='primaryOutlined'
                  onClick={handleMaterialsClick}
                  disabled={isDownloading}
               >
                  {renderDownloadButton()}
               </Button>
            </BookDownload>
         </>}
      </BookPlanHolder>
   );
};

export default BookPlan;