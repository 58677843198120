import { PROD_API } from "./api"

export const COURSES = {
   oratorMastery: 'oratorMastery',
   kidsExercises: 'kidsExercises',
   parentAndChild: 'parentAndChild',
   kidsLessonsPrepare: 'kidsLessonsPrepare',
   restoreBroken: 'restoreBroken',
   restoreBrokenPremium: 'restoreBrokenPremium',
   artKidsExercises: 'artKidsExercises',
   mentalMath: 'mentalMath',
   urbanHigh: 'urbanHigh',
   urbanTop: 'urbanTop',
   relationshipGod: 'relationshipGod',
   prepareToSuccess: 'prepareToSuccess',
   parentsSchool: 'parentsSchool',
   audioAscent: 'audioAscent',
   audioSand: 'audioSand',
   audioForest: 'audioForest',
   workWithNegativeEmotions: 'workWithNegativeEmotions',
   sandTherapyTechnique: 'sandTherapyTechnique',
   notSelected: 'noSelected',
   personalities: 'personalities',
   internetSecurity: 'internetSecurity',
   puzzleShow: 'puzzleShow',
   fiveDirections: 'fiveDirections',
   familyContext: 'familyContext',
   urbanShow: 'urbanShow',
   gameLab: 'gameLab',
   thematicActivity: 'thematicActivity',
   creativeRestoration: 'creativeRestoration',
   bibleKidsLessons: 'bibleKidsLessons',
   innovationLeadershipStudio: 'innovationLeadershipStudio',
   basicsOfConsulting: 'basicsOfConsulting',
   // not added in BE
   buckleUp: 'buckleUp',
   buckleUpProposal: 'buckleUpProposal',
   enterAltitude: 'enterAltitude',
   enterAltitudeProposal: 'enterAltitudeProposal',
   moveUpwards: 'moveUpwards',
   moveUpwardsProposal: 'moveUpwardsProposal',
   chooseDirectionProposal: 'chooseDirectionProposal',
   chooseDirection: 'chooseDirection',
   maintainBalance: 'maintainBalance',
   maintainBalanceProposal: 'maintainBalanceProposal',
   discoverHorizons: 'discoverHorizons',
   onlyMaterials: 'onlyMaterials',
   familyWithGod: 'familyWithGod',
   
}

export const SECTIONS = {
   artTherapy: "Art-therapy",
   eventLab: 'Event-lab',
   show: "Show",
   teens: 'Teens',
   youth: 'Youth',
   kids: 'Kids',
   custom: 'Custom'
}

export const PERSONALITIES = {
   analytic: "Analytic",
   diplomat: 'Diplomat',
   keeper: "Keeper",
   finder: 'Finder',
   custom: 'notSelected'
}

export const PERSONALITY = {
   strategic: "strategic",
   logician: 'logician',
   commander: "commander",
   debater: 'debater',
   advocate: 'advocate',
   mediator: 'mediator',
   protagonist: 'protagonist',
   campaigner: 'campaigner',
   logistician: 'logistician',
   defender: 'defender',
   executive: 'executive',
   consul: 'consul',
   virtuoso: 'virtuoso',
   adventurer: 'adventurer',
   entrepreneur: 'entrepreneur',
   entertainer: 'entertainer',
   noSelected: 'noSelected'
}

export const LECTORS = {
   volodimirUbeivolc: 'volodimirUbeivolc',
   olgaVoronova: 'olgaVoronova',
   sergheiMihailov: 'sergheiMihailov',
   iuliaUbeivolk: 'iuliaUbeivolk',
   veraCrapunarli: 'veraCrapunarli',
   sergheiSemaev: 'sergheiSemaev',
   vitaliiGrecov: 'vitaliiGrecov',
   polinaSozonova: 'polinaSozonova',
   annaVasiltsova: 'annaVasiltsova',
   ekaterinaVoronova: 'ekaterinaVoronova',
   annaUbeivolc: 'annaUbeivolc',
   sergheiProcopov: 'sergheiProcopov',
   innaCiriac: 'innaCiriac',
   urbanUser: 'urbanUser',
}

export const API_ALL_COURSES = `${PROD_API}/api/courses/`;
export const API_POPULAR_COURSES = `${PROD_API}/api/courses/popular`;
export const API_PREMIUM_COURSES = `${PROD_API}/api/courses/premium`;

