import { NavigationItems } from './NavigationItems/NavigationItems';
import decorationBG from '../../assets/global/decorationBG.png';
import { NavigationMenuWrapper, NavigationBGStyledImg } from './styled';
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import NavigationLabels from './NavigationLabels/NavigationLabels';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';

export const NavigationMenu = () => {

   const { isMobile } = useMediaQuery();

   if (isMobile) {
      return <MobileNavigation />;
   }

   return (
      <NavigationMenuWrapper>
         <NavigationBGStyledImg src={decorationBG} alt="" />
         <NavigationItems />
         <NavigationLabels />
         <TermsAndConditions />
      </NavigationMenuWrapper>
   );
};

