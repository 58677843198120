import { COLORS } from '../../constants/colors';
import { CursesSections } from 'types/common';
import { SECTIONS } from '../../constants/courses';

export const getCourseColor = (section: CursesSections): string => {
  switch (section) {
    case SECTIONS.artTherapy:
      return COLORS.artTherapy;
    case SECTIONS.kids:
      return COLORS.kids;
    case SECTIONS.youth:
      return COLORS.youth;
    case SECTIONS.teens:
      return COLORS.teens;
    case SECTIONS.eventLab:
      return COLORS.eventLab;
    case SECTIONS.show:
        return COLORS.show;
    case 'Magenta' as CursesSections:
      return COLORS.magenta;
    case 'Premium' as CursesSections:
      return COLORS.premium;
    default:
      return COLORS.blackLabel;
  }
}
