
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const ModalHeaderWrapper = styled.div<{ width: number }>`
   background-color: ${COLORS.woodSmoke};
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   display: flex;
   justify-content: space-between;
   width: ${({ width }) => width}px;
   margin-top: -2px;
   color: ${COLORS.white};
   padding-left: 20px;
   padding-right: 20px;

   h3 {
      font-weight: 500;
      font-size: 16px;
   }

   .left-side {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 110px;
   }
`;

export const ModalWrapperBox = styled.div<{ width: number, bgcolor: string; height: number }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: ${({ width }) => width}px;
   height: ${({ height }) => height}px;
   background-color: ${({ bgcolor }) => bgcolor};
   border-radius: 10px;
   box-shadow: 24px;
   padding: 4px;
`;

export const SideModalWrapperBox = styled.div<{ width: number, bgcolor: string; height: number }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   position: absolute;
   top: 0;
   right: 0;
   width: ${({ width }) => width}px;
   height: 100vh;
   background-color: ${({ bgcolor }) => bgcolor};
   border-radius: 10px;
   box-shadow: 24px;
   padding: 4px;
`;



export const SideModalHeaderWrapper = styled.div<{ width: number }>`
   background-color: ${COLORS.woodSmoke};
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   display: flex;
   justify-content: space-between;
   width: ${({ width }) => width}px;
   margin-top: -2px;
   color: ${COLORS.white};
   padding-left: 20px;
   padding-right: 20px;

   h3 {
      font-weight: 500;
      font-size: 16px;
   }

   span {
      margin-top: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
      text-transform: uppercase;
      display: flex;

      img {
         width: 14px;
         height: 14px;
         margin-right: 10px;
         margin-top: 13px;
      }
   }

   .left-side {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
`;