import {
   Route,
   Routes,
} from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Main from './Main/Main';
import AllCourses from './Courses/AllCourses';
import Cabinet from './Cabinet/Cabinet';
import RestoreBroken from './Courses/RestoreBroken/RestoreBroken';
import PrepareToSuccess from './Courses/PrepareToSuccess/PrepareToSuccess';
import BuckleUpProposal from "./Courses/BuckleUpProposal/BuckleUpProposal";
import BookReader from './BookReader/BookReader';
import MentalMath from './Courses/MentalMath/MentalMath';
import Personalities from './Courses/Personalities/Personalities';
import FiveDirections from './Courses/FiveDirections/FiveDirections';
import GameLab from './Courses/GameLab/GameLab';
import { COURSES, SECTIONS } from '../../constants/courses';
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';
import Settings from './Settings/Settings';
import EventLab from './EventLab/EventLab';
import Art from "./Art/Art";
import ParentsSchool from './Courses/ParentsSchool/ParentsSchool';
import Show from './Show/Show';
import Teens from './Teens/Teens';
import { CoursesModifications } from 'types/courses/courses';
import { getAllCourses } from '../../store/actions/appActions';
import Kids from "./Kids/Kids";
import Youth from './Youth/Youth';

import { ActiveTabWrapper } from './styled';
import { selectUserProfile } from "../../store/selectors/user";
import ProtectedRoute from "../Pages/ProtectedRoute/ProtectedRoute";
import TermsPage from "./TermsPage/TermsPage";
import EnterAltitudeProposal from "./Courses/EnterAltitudeProposal/EnterAltitudeProposal";
import MoveUpwardsProposal from "./Courses/MoveUpwardsProposal/MoveUpwardsProposal";
import ChooseDirectionProposal from "./Courses/ChooseDirectionProposal/ChooseDirectionProposal";
import MaintainBalanceProposal from "./Courses/MaintainBalanceProposal/MaintainBalanceProposal";




const TabsContainer = () => {
   const dispatch = useDispatch();
   const { isMobile, width } = useMediaQuery();
   const user = useSelector(selectUserProfile)

   useEffect(() => {
      dispatch(getAllCourses() as any)
   }, [])

   return (
      <ActiveTabWrapper isMobile={isMobile} width={width}>
         <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/courses" element={<AllCourses />} />
            <Route path="/cabinet/*" element={<Cabinet />} />
            <Route path={`/${SECTIONS.eventLab}`} element={<EventLab />} />
            <Route path={`/${SECTIONS.artTherapy}`} element={<Art />} />
            <Route path={`/${SECTIONS.teens}`} element={<Teens />} />
            <Route path={`/${SECTIONS.show}`} element={<Show />} />
            <Route path={`/${SECTIONS.kids}`} element={<Kids />} />
            <Route path={`/${SECTIONS.youth}`} element={<Youth />} />
            <Route
               path="/settings"
               element={<ProtectedRoute isAuthenticated={!!user}>
                  <Settings />
               </ProtectedRoute>} />
            <Route path={`/cabinet/${COURSES.restoreBroken}`} element={<RestoreBroken />} />
            <Route path={`/cabinet/${COURSES.buckleUpProposal}`} element={<BuckleUpProposal />} />
            <Route path={`/cabinet/${COURSES.enterAltitudeProposal}`} element={<EnterAltitudeProposal />} />
            <Route path={`/cabinet/${COURSES.moveUpwardsProposal}`} element={<MoveUpwardsProposal />} />
            <Route path={`/cabinet/${COURSES.chooseDirectionProposal}`} element={<ChooseDirectionProposal />} />
            <Route path={`/cabinet/${COURSES.maintainBalanceProposal}`} element={<MaintainBalanceProposal />} />
            <Route path={`/cabinet/${COURSES.prepareToSuccess}`} element={<PrepareToSuccess />} />
            <Route path={`/cabinet/${COURSES.fiveDirections}`} element={<FiveDirections />} />
            <Route path={`/cabinet/${COURSES.gameLab}`} element={<GameLab />} />
            <Route
               path={`cabinet/${COURSES.prepareToSuccess}/reader`}
               element={<BookReader modification={COURSES.prepareToSuccess as CoursesModifications} />}
            />
            <Route
               path={`cabinet/${COURSES.relationshipGod}/reader`}
               element={<BookReader modification={COURSES.relationshipGod as CoursesModifications} />}
            />
            <Route
               path={`cabinet/${COURSES.familyWithGod}/reader`}
               element={<BookReader modification={COURSES.familyWithGod as CoursesModifications} />}
            />
            <Route
               path={`cabinet/${COURSES.fiveDirections}/reader`}
               element={<BookReader modification={COURSES.fiveDirections as CoursesModifications} />}
            />
            <Route
               path={`cabinet/materials/reader`}
               element={<BookReader modification={COURSES.onlyMaterials as CoursesModifications} />}
            />
            <Route
               path={`/cabinet/${COURSES.buckleUp}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.buckleUp)}>
                  <BookReader modification={COURSES.buckleUp as CoursesModifications} />
               </ProtectedRoute>} />
            <Route
               path={`/cabinet/${COURSES.enterAltitude}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.enterAltitude)}>
                  <BookReader modification={COURSES.enterAltitude as CoursesModifications} />
               </ProtectedRoute>} />
            <Route
               path={`/cabinet/${COURSES.moveUpwards}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.moveUpwards)}>
                  <BookReader modification={COURSES.moveUpwards as CoursesModifications} />
               </ProtectedRoute>} />
            <Route
               path={`/cabinet/${COURSES.chooseDirection}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.chooseDirection)}>
                  <BookReader modification={COURSES.chooseDirection as CoursesModifications} />
               </ProtectedRoute>} />
               <Route
               path={`/cabinet/${COURSES.maintainBalance}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.maintainBalance)}>
                  <BookReader modification={COURSES.maintainBalance as CoursesModifications} />
               </ProtectedRoute>} />
            <Route
               path={`/cabinet/${COURSES.creativeRestoration}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.creativeRestoration)}>
                  <BookReader modification={COURSES.creativeRestoration as CoursesModifications} />
               </ProtectedRoute>} />
            <Route
               path={`/cabinet/${COURSES.bibleKidsLessons}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.bibleKidsLessons)}>
                  <BookReader modification={COURSES.bibleKidsLessons as CoursesModifications} />
               </ProtectedRoute>} />
            <Route
               path={`/cabinet/${COURSES.innovationLeadershipStudio}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.innovationLeadershipStudio)}>
                  <BookReader modification={COURSES.innovationLeadershipStudio as CoursesModifications} />
               </ProtectedRoute>} />
               <Route
               path={`/cabinet/${COURSES.basicsOfConsulting}/reader`}
               element={<ProtectedRoute isAuthenticated={!!user?.premium_courses.includes(COURSES.basicsOfConsulting)}>
                  <BookReader modification={COURSES.basicsOfConsulting as CoursesModifications} />
               </ProtectedRoute>} />
               
            <Route path={`/cabinet/${COURSES.mentalMath}`} element={<MentalMath />} />
            <Route path={`/cabinet/${COURSES.personalities}`} element={<Personalities />} />
            <Route path={`/cabinet/${COURSES.parentsSchool}`} element={<ParentsSchool />} />
            <Route path={`/urban_terms`} element={<TermsPage />} />
         </Routes>
      </ActiveTabWrapper>
   );
}

export default TabsContainer;