import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useNavigate , useLocation} from 'react-router-dom';

import leftBlackArrow from '../../../assets/icons/leftBlackArrow.png'
import LeftMenu from '../../UI/LeftMenu/LeftMenu';
import { selectCurrentPage, selectCurrentConfig } from '../../../store/selectors/book';
import { setCurrentBookConfig, setLeftMenu } from '../../../store/actions/bookActions';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import FirstTimeTooltip from '../../UI/BookComponents/FirstTimeTooltip';
import BookPlan from './BookPlan';
import { selectCurrentCourse } from '../../../store/selectors/courses';
import { getCourseBookConfig } from '../../../utils/courses/getCourseBookConfig';
import BookRequestTooltip from '../../UI/BookComponents/BookRequestTooltip';
import { COURSES, SECTIONS } from '../../../constants/courses';
import { CoursesModifications } from '../../../types/courses/courses';
import { setCurrentCourse } from '../../../store/actions/appActions';

import {
   BoorReaderWrapper as BookReaderWrapper,
   BookContentHolder,
   PageFlipper,
   PrevPage,
   NextPage,
   OpenMenuIcon,
   CloseMenuIcon
} from './styled';
import { CursesSections } from 'types/common';
import { selectCurrentLanguage } from '../../../store/selectors/translation';
import { selectUserProfile } from '../../../store/selectors/user';
import BookLanguageTooltip from '../../UI/BookComponents/BookLanguageTooltip';


type Props = {
   modification: CoursesModifications;
}

const BookReader = ({ modification }: Props) => {
   const dispatch = useDispatch();
   const currentPage = useSelector(selectCurrentPage)
   const currentConfig = useSelector(selectCurrentConfig)
   const currentCourse = useSelector(selectCurrentCourse)
   const [numPages, setNumPages] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const isFirstPage = pageNumber > 1;
   const { isMobile, width } = useMediaQuery();
   const isSmallScreen = width < 1200;
   const navigate = useNavigate();
   const search = useLocation().search
   const searchParams = new URLSearchParams(search)
   const materialsModification = searchParams.get('materials') as string;
   const userProfile = useSelector(selectUserProfile)
   const currentLanguage = useSelector(selectCurrentLanguage)
   const bookLanguage = userProfile?.language || currentLanguage
   const isLastPage = isSmallScreen ? pageNumber < numPages : pageNumber < numPages - 1;
   
   const handleCloseLeftMenu = () => {
      dispatch(setLeftMenu({ isLeftMenuOpen: false }))
   }

   const [isModalOpen, setIsModalOpen] = useState(false);
   const [hasOpenedModal, setHasOpenedModal] = useState(false);

   useEffect(() => {
      if (materialsModification && !hasOpenedModal) {
         setIsModalOpen(true);
         setHasOpenedModal(true); // Update state to indicate that modal has been opened
      }
   }, [materialsModification, hasOpenedModal]);

   useEffect(() => {
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
   }, []);

   useEffect(() => {
      setPageNumber(1)
      dispatch(setCurrentCourse({ courseModification: modification }))
   }, [currentPage])

   useEffect(() => {
      dispatch(setCurrentBookConfig({
         currentConfig: getCourseBookConfig(currentCourse, materialsModification, bookLanguage)
      }))
   }, [currentCourse, bookLanguage])


   const nextPage = () => {
      handleCloseLeftMenu()
      document.getElementById('react-pdf__Page__canvas');
      if (isLastPage) {
         setPageNumber(isSmallScreen ? pageNumber + 1 : pageNumber + 2)
      }
   }

   const prevPage = () => {
      handleCloseLeftMenu()
      if (isFirstPage) {
         setPageNumber(isSmallScreen ? pageNumber - 1 : pageNumber - 2)
      }
   }

   function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
      setNumPages(numPages);
   }

   if (currentCourse === COURSES.notSelected) {
      navigate('/cabinet')
      return <div>Выберите курс</div>}

   return (
      <>
         <BookReaderWrapper isMobile={isMobile} width={width}>
            <BookContentHolder isMobile={isMobile}>
               <div>
                  <Document
                     file={currentConfig[currentPage]?.file}
                     onLoadSuccess={onDocumentLoadSuccess}
                     error='Не получилось загрузить учебник'
                     loading={<CircularProgress color="secondary" /> }
                     renderMode='canvas'
                  >
                     <div style={{ display: 'flex' }}>
                        <Page pageNumber={pageNumber} />
                        {!isSmallScreen && <Page pageNumber={pageNumber + 1} />}
                     </div>
                  </Document>
                  <PageFlipper isMobile={isMobile}>
                     <PrevPage isMobile={isMobile} isAvailable={isFirstPage} onClick={() => prevPage()}>
                        <OpenMenuIcon isMobile={isMobile} src={leftBlackArrow} />
                     </PrevPage>
                     <NextPage isMobile={isMobile} isAvailable={isLastPage} onClick={() => nextPage()}>
                        <CloseMenuIcon isMobile={isMobile} src={leftBlackArrow} />
                     </NextPage>
                  </PageFlipper>
               </div>
            </BookContentHolder>
            <FirstTimeTooltip />
            <BookRequestTooltip section={SECTIONS.youth as CursesSections} bookModification={currentCourse} />
            <BookLanguageTooltip section={SECTIONS.kids as CursesSections} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}  />
         </BookReaderWrapper>
         <LeftMenu>
            <BookPlan />
         </LeftMenu>
      </>
   );
}

export default BookReader;