import { COURSES } from '../../constants/courses';

import kidsLessonsPrepare from '../../assets/courses/kidsLessonsPrepare.png'
import oratorMastery from '../../assets/courses/oratorMastery.png'
import restoreBroken from '../../assets/courses/restoreBroken.png'
import restoreBrokenPremium from '../../assets/courses/restoreBrokenPremium.png'
import kidsExercises from '../../assets/courses/kidsExercises.png'
import parentAndChild from '../../assets/courses/parentAndChild.png'
import artKidsExercises from '../../assets/courses/artKidsExercises.png'
import mentalMath from '../../assets/courses/mentalMath.png'
import urbanHigh from '../../assets/courses/urbanHigh.png'
import urbanTop from '../../assets/courses/urbanTop.png'
import relationshipGod from '../../assets/courses/relationshipGod.png'
import prepareToSuccess from '../../assets/courses/prepareToSuccess.png'
import parentsSchool from '../../assets/courses/parentsSchool.png'
import audioAscent from '../../assets/courses/audioAscent.png'
import audioSand from '../../assets/courses/audioSand.png'
import audioForest from '../../assets/courses/audioForest.png'
import workWithNegativeEmotions from '../../assets/courses/workWithNegativeEmotions.png'
import sandTherapyTechnique from '../../assets/courses/sandTherapyTechnique.png'
import personalities from '../../assets/courses/personalities.png'
import internetSecurity from '../../assets/courses/internetSecurity.png'
import puzzleShow from '../../assets/courses/puzzleShow.png'
import fiveDirections from '../../assets/courses/fiveDirections.png'
import familyContext from '../../assets/courses/familyContext.png'
import urbanShow from '../../assets/courses/urbanShow.png'
import gameLab from '../../assets/courses/gameLab.png'
import thematicActivity from '../../assets/courses/thematicActivity.png'
import creativeRestoration from '../../assets/courses/creativeRestoration.png'
import bibleKidsLessons from '../../assets/courses/bibleKidsLessons.png'
import innovationLeadershipStudio from '../../assets/courses/innovationLeadershipStudio.png'
import basicsOfConsulting from '../../assets/courses/basicsOfConsulting.png'
import buckleUpProposal from '../../assets/courses/buckleUpProposal.png'
import buckleUp from '../../assets/courses/buckleUp.png'
import enterAltitudeProposal from '../../assets/courses/enterAltitudeProposal.png'
import enterAltitude from '../../assets/courses/enterAltitude.png'
import moveUpwardsProposal from '../../assets/courses/moveUpwardsProposal.png'
import chooseDirection from '../../assets/courses/chooseDirection.png'
import maintainBalance from '../../assets/courses/maintainBalance.png'
import familyWithGod from '../../assets/courses/familyWithGod.png'
import moveUpwards from '../../assets/courses/moveUpwards.png'
import chooseDirectionProposal from '../../assets/courses/chooseDirectionProposal.png'
import maintainBalanceProposal from '../../assets/courses/maintainBalanceProposal.png'

export const getCourseImage = (courseModification: string): string => {
  switch (courseModification) {
    case COURSES.kidsLessonsPrepare:
      return kidsLessonsPrepare;
    case COURSES.oratorMastery:
      return oratorMastery;
    case COURSES.restoreBroken:
      return restoreBroken;
    case COURSES.kidsExercises:
      return kidsExercises;
    case COURSES.parentAndChild:
      return parentAndChild;
    case COURSES.artKidsExercises:
      return artKidsExercises;
    case COURSES.mentalMath:
      return mentalMath;
    case COURSES.urbanHigh:
      return urbanHigh;
    case COURSES.urbanTop:
      return urbanTop;
    case COURSES.relationshipGod:
      return relationshipGod;
    case COURSES.prepareToSuccess:
      return prepareToSuccess;
    case COURSES.parentsSchool:
      return parentsSchool;
    case COURSES.audioAscent:
      return audioAscent;
    case COURSES.audioSand:
      return audioSand;
    case COURSES.audioForest:
      return audioForest;
    case COURSES.workWithNegativeEmotions:
      return workWithNegativeEmotions;
    case COURSES.sandTherapyTechnique:
      return sandTherapyTechnique;
    case COURSES.personalities:
      return personalities;
    case COURSES.internetSecurity:
      return internetSecurity;
    case COURSES.puzzleShow:
      return puzzleShow;
    case COURSES.fiveDirections:
      return fiveDirections;
    case COURSES.familyContext:
      return familyContext;
    case COURSES.urbanShow:
      return urbanShow;
    case COURSES.gameLab:
      return gameLab;
    case COURSES.thematicActivity:
      return thematicActivity;
    case COURSES.creativeRestoration:
      return creativeRestoration;
    case COURSES.bibleKidsLessons:
      return bibleKidsLessons;
    case COURSES.restoreBrokenPremium:
      return restoreBrokenPremium;
    case COURSES.innovationLeadershipStudio:
      return innovationLeadershipStudio;
    case COURSES.basicsOfConsulting:
      return basicsOfConsulting;
    case COURSES.buckleUpProposal:
      return buckleUpProposal;
    case COURSES.buckleUp:
      return buckleUp;
    case COURSES.enterAltitudeProposal:
      return enterAltitudeProposal;
    case COURSES.enterAltitude:
      return enterAltitude;
    case COURSES.moveUpwardsProposal:
      return moveUpwards;
    case COURSES.moveUpwards:
      return moveUpwardsProposal;
    case COURSES.chooseDirection:
      return chooseDirectionProposal;
    case COURSES.chooseDirectionProposal:
      return chooseDirection;
    case COURSES.maintainBalanceProposal:
      return maintainBalanceProposal;
    case COURSES.maintainBalance:
      return maintainBalance;
    case COURSES.familyWithGod:
      return familyWithGod;
    default:
      return kidsLessonsPrepare;
  }
}
