
import styled from 'styled-components';
import { NAVIGATION_WIDTH } from '../../../constants/dimensions';

export const MainWrapper = styled.div<{ isMobile: boolean }>`
   align-items: flex-start;
   margin: 10px 20px;
   width: calc(100vw - ${({ isMobile }) => isMobile ? 0 : NAVIGATION_WIDTH + 10}px);
   height: ${({ isMobile }) => isMobile ? 'calc(100vh - 140px)' : 'calc(100vh - 80px)'}};
   overflow-x: hidden;
   overflow-y: scroll;
`;

export const MainTopRow = styled.div<{ isMobile: boolean }>`
   display: ${({ isMobile }) => isMobile ? 'block' : 'flex'};
   align-items: flex-start;
   width: 100vw;
`;

export const MainBottomRow = styled.div<{ isMobile: boolean }>`
   display: flex;
   align-items: flex-start;
   width: calc(100vw - ${({ isMobile }) => isMobile ? 0 : NAVIGATION_WIDTH + 50}px);
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
`;

export const RowWrapper = styled.div<{ isSmallScreen: boolean }>`
   display: flex;
   flex-wrap: wrap;  
   flex-direction: ${({ isSmallScreen }) => isSmallScreen ? 'column-reverse' : ''};
`;