import { COLORS } from '../../constants/colors';
import { HEADER_HEIGHT, NAVIGATION_WIDTH } from '../../constants/dimensions';
import styled from 'styled-components';

export const NavigationMenuWrapper = styled.div`
   position: fixed;
   top: ${HEADER_HEIGHT}px;
   width: ${NAVIGATION_WIDTH}px;
   height: 100%;
   background-color: ${COLORS.urbanBlack};
   display: flex;
   flex-direction: column;
`;

export const NavigationBGStyledImg = styled.img`
   position: fixed;
   top: 500px;
   width: ${NAVIGATION_WIDTH}px;
   pointer-events: none;
   user-select: none;
`;