import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';

import maib from '../../../assets/global/maib.png';

import { selectUserPaymentStatus } from '../../../store/selectors/user';
import { COLORS } from '../../../constants/colors';

import {
   LastStepWrapper,
   FirstStepHolderForm,
   FormTitle,
   WaitingTitle,
   WaitingSubtitle
} from '../styled'

function CircularProgressWithLabel(
   props: CircularProgressProps & { value: number, minutes: number, seconds: number },
) {

   const convertSecondsToMinutes = (seconds: number, minutes: number) => {
      const remainingSeconds = seconds % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
   };

   return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
         <CircularProgress
            style={{
               color: COLORS.magenta,
            }} variant="determinate" {...props} />
         <Box
            sx={{
               top: 0,
               left: 0,
               bottom: 0,
               right: 0,
               position: 'absolute',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
         >
            <Typography
               variant="caption"
               component="div"
               color="white"
               style={{
                  fontSize: '1.7rem',
                  fontWeight: 700,
                  fontFamily: 'Montserrat',
                  marginLeft: '-0.6rem',
               }}
            >{`${convertSecondsToMinutes(props.seconds, props.minutes)}`}</Typography>
         </Box>
      </Box>
   );
}


const SecondStep = ({ handleAddCourseToUser }: {
   handleAddCourseToUser?: () => void;
}) => {
   const paymentStatus = useSelector(selectUserPaymentStatus);
   const [time, setTime] = useState(10 * 60);
   const [percentage, setPercentage] = useState(100);


   useEffect(() => {
      const interval = setInterval(() => {
         setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
         setPercentage((prevPercentage) => (prevPercentage > 0 ? prevPercentage - 0.1 : 0));
      }, 1000);

      return () => clearInterval(interval);
   }, []);

   const minutes = Math.floor(time / 60);
   const seconds = time % 60;

   useEffect(() => {
      if (paymentStatus === 'payed' && handleAddCourseToUser) {
         handleAddCourseToUser();
      }
   }, [paymentStatus]);

   return <FirstStepHolderForm>
      <FormTitle>
         <p>Ожидание оплаты</p>

         <div className='powered'>
            <div className='powered-text'>Powered by  </div> <img src={maib} alt="" />
         </div>
      </FormTitle>
      <LastStepWrapper>
         <CircularProgressWithLabel value={percentage} minutes={minutes} seconds={seconds} />
      </LastStepWrapper>
      <WaitingTitle>
         Ожидание оплаты, подождите пока завершиться оплата
      </WaitingTitle>
      <WaitingSubtitle>
         Ваша оплата 800 MDL для Urban


      </WaitingSubtitle>
   </FirstStepHolderForm>
};

export default SecondStep;