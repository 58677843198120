
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import { useSnackbar } from 'notistack';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import { setSetLoginPageStatus, filterCourses, labelCourses } from '../../../store/actions/appActions';
import { loginUser, resetUserPassword, setAuthError } from '../../../store/actions/userActions';
import { selectAuthError, selectUserData } from '../../../store/selectors/user';
import logo from '../../../assets/header/logo-urban.svg';
import loginPageBg from '../../../assets/global/loginPageBg.png';
import { Input } from '../../UI/Input/Input';
import { Button } from '../../UI/Button/index';
import { COLORS } from '../../../constants/colors';
import closeButtonWhite from '../../../assets/courses/personalities/closeButtonWhite.png';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import {
   LoginPageContainer,
   LoginRightSide,
   LoginLeftSide,
   LoginTitle,
   HolderForm,
   InputTitle,
   InputSubtitle,
   VerticalSeparator,
   RegisterSubtitle,
   LogoRow
} from './styled';

const LoginPage = () => {
   const dispatch = useDispatch();
   const user = useSelector(selectUserData);
   const authError = useSelector(selectAuthError);
   const navigate = useNavigate();
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery()
   const { enqueueSnackbar } = useSnackbar();
   const [isResetPasswordPage, setIsResetPasswordPage] = useState(false)

   useEffect(() => {
      if (authError) {
         enqueueSnackbar(authError, { variant: "error" })
         setTimeout(() => {
            dispatch(setAuthError({ authError: null }))
         }, 1000)
      }
   }, [authError])

   useEffect(() => {
      dispatch(setSetLoginPageStatus({ isLoginPageOpen: true }))
      dispatch(filterCourses({ filter: 'all' }))
      dispatch(labelCourses({ label: 'none' }))

      return () => {
         dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
      }
   }, [])

   useEffect(() => {
      if (user) {
         dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
         navigate('/')
      }
   }, [user])

   const handleLogin = ({ username, password }: { username: string, password: string }) => {
      dispatch(loginUser({ username, password }) as any)
   }

   const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { username, password } = e.target as HTMLFormElement;
      handleLogin({ username: username.value, password: password.value })
   }

   const handleOnSubmitResetPassword = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { email } = e.target as HTMLFormElement;
      dispatch(resetUserPassword({ 
         email: email.value,
         callback: () => enqueueSnackbar('Письмо отправлено, проверьте почту', {variant: 'success'}),
         callbackError: () => enqueueSnackbar('Такого пользователя нет', {variant: 'error'})
       }) as any)
   }

   const handleCloseLoginPage = () => {
      dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
      navigate('/')
   }

   const handleResetPassword = () => {
      setIsResetPasswordPage(true)
   }

   const handleCloseResetPassword = () => {
      setIsResetPasswordPage(false)
   }

   return (
      <LoginPageContainer>
         <LoginLeftSide isMobile={isMobile}>
            <LogoRow>
               <img className='logo' src={logo} alt="" />
               <img onClick={handleCloseLoginPage} className='close' src={closeButtonWhite} alt="" />
            </LogoRow>
            <LoginTitle isMobile={isMobile}>{isResetPasswordPage ? t('login.reset') : t('login.title')}</LoginTitle>
            {isResetPasswordPage ?
               <HolderForm onSubmit={handleOnSubmitResetPassword}>
                  <InputTitle>
                     {t('login.email')}
                  </InputTitle>
                  <Input
                     type='text'
                     name="email"
                     placeholder={t('login.enterEmail')}
                     modifier='primary'
                     icon={<AlternateEmailIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                  />
                  <InputSubtitle onClick={handleCloseResetPassword}>
                     {t('login.back')}
                  </InputSubtitle>
                  <Button
                     modifier='primaryWhiteOutlined'
                     htmlType='submit'
                  >
                     {t('login.send')}
                  </Button>
               </HolderForm>
               : <HolderForm onSubmit={handleOnSubmit}>
                  <InputTitle>
                     {t('login.login')}
                  </InputTitle>
                  <Input
                     type='text'
                     name="username"
                     placeholder={t('login.enterLogin')}
                     modifier='primary'
                     icon={<PersonIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                  />
                  <InputTitle>
                     {t('login.password')}
                  </InputTitle>
                  <Input
                     type='password'
                     name="password"
                     placeholder={t('login.enterPassword')}
                     modifier='primary'
                     icon={<PasswordIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                  />
                  <InputSubtitle onClick={handleResetPassword}>
                     {t('login.forgotPassword')}
                  </InputSubtitle>
                  <Button
                     modifier='primaryWhiteOutlined'
                     htmlType='submit'
                  >
                     {t('login.submit')}
                  </Button>
                  <VerticalSeparator />
                  {/*  <Button
                  modifier='primaryOutlined'
                  htmlType='submit'
                  icon={<img src={google} alt="" />}
               >
                  {t('login.loginGoogle')}
               </Button> */}
               </HolderForm>}
            {!isResetPasswordPage && <Link to={'/registration'}>
               <RegisterSubtitle>
                  {t('login.register')}
               </RegisterSubtitle>
            </Link>}
         </LoginLeftSide>
         {!isMobile && <LoginRightSide>
            <img src={loginPageBg} alt="" />
         </LoginRightSide>}
      </LoginPageContainer>
   );
};

export default LoginPage;