import styled from "styled-components";

import { COLORS } from "../../../../constants/colors";
import { NAVIGATION_WIDTH } from "../../../../constants/dimensions";

import cabinetBGwhite from '../../../../assets/global/cabinetBGwhite.png';

export const CabinetPrivateWrapper = styled.div<{ isMobile: boolean }>`
   align-items: flex-start;
   width: calc(100vw - ${({ isMobile }) => isMobile ? 0 : NAVIGATION_WIDTH - 10}px);
   height: ${({ isMobile }) => isMobile ? 'auto' : 'calc(100vh - 20px)'}};
   overflow-y: ${({ isMobile }) => isMobile ? 'scroll' : 'hidden'}};
   overflow-x: hidden;
   color: ${COLORS.white};
   background-image: url(${cabinetBGwhite});
   background-repeat: no-repeat;
   background-size: cover;
   padding: 20px;
`;


export const TitleRowWrapper = styled.div<{ isMobile: boolean }>`
   display: flex
   justify-content: space-between;
   width: calc(100vw - ${({ isMobile }) => isMobile ? 0 : NAVIGATION_WIDTH + 600}px);
   align-items: center;
   overflow: hidden;
   position: ${({ isMobile }) => isMobile ? 'unset' : 'sticky'};
   top: 0;
   z-index: 2;
   color: ${COLORS.blackLabel};
`;

export const CabinetTopRow = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
   gap: 20px;
   margin-bottom: 20px;
`;

export const StartedCoursesWrapper = styled.div<{ isMobile: boolean }>`
   overflow-x: hidden;
   overflow-y: scroll;
   display: flex;
   flex-direction: column;
   gap: 20px;
   max-height: ${({ isMobile }) => isMobile ? '100%' : '320px'};
   height: ${({ isMobile }) => isMobile ? 'auto' : '320px'};
`;

export const MyCoursesWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-wrap: wrap;
   gap: 20px;
   height: 390px;
   overflow-y: scroll;
`;

export const OneCourseWrapper = styled.div`
   width: 270px;
   margin-right: 10px;
   margin-bottom: 20px;
`;

export const NoCoursesInCabinetWrapper = styled.div`
   width: 100%;
   position: relative;

   img {
      width: 98%;
   }
`;

export const NoBookmarksInCabinetWrapper = styled.div`
   width: 100%;
   position: relative;

   img {
      max-height: 380px;
   }
`;

export const CabinetBottomRow = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   height: ${({ isMobile }) => isMobile ? 'auto' : 'calc(100vh - 500px)'}};
`;

export const BookmarkedCoursesWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-wrap: wrap;
   width: 90vw;
   overflow-y: scroll;
   margin-top: 20px;
   gap: 10px;
   margin-bottom: ${({ isMobile }) => isMobile ? '80px' : '20px'};
`; 