import roundArrowBook from '../../../assets/global/roundArrowBook.svg';

import { OpenMenuIcon, LeftMenuControllerWrapper, CloseMenuIcon } from './styled';

type Props = {
   toggleLeftMenu: () => void;
   isLeftMenuOpened: boolean;
}

const LeftMenuController = ({ toggleLeftMenu, isLeftMenuOpened }: Props) => {
   return (
      <LeftMenuControllerWrapper isLeftMenuOpened={isLeftMenuOpened} onClick={toggleLeftMenu}>
         {!isLeftMenuOpened ? <OpenMenuIcon src={roundArrowBook} /> : <CloseMenuIcon src={roundArrowBook} />}
      </LeftMenuControllerWrapper>
   );
};

export default LeftMenuController;