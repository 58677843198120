import { createGlobalStyle, css } from 'styled-components';

import { COLORS } from '../constants/colors';

export const GlobalStyle = createGlobalStyle(
  () => css`
    *,
    *:after,
    *:before {
      outline: none;
      box-sizing: border-box;
    }

    html,
    body,
    #__next {
      margin: 0;
      padding: 0;
      height: 100%;
    }

    body {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      line-height: 24px;
      background: ${COLORS.urbanBaGLight};
      height: 100vh;
    }

    a {
      color: #ffffff;
      text-decoration: none;
      background-color: transparent;
    }

    a:not([href]):not([class]) {
      color: inherit;
      text-decoration: none;
    }

    a:not([href]):not([class]):hover {
      color: inherit;
      text-decoration: none;
    }

    ol,
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    blockquote,
    q {
      quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    h1 {
      &.bold {
        font-weight: bold;
      }
    }
    h3 {
      &.sub {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 10%;
      }
    }
    h4 {
      &.bold {
        font-weight: bold;
      }

      &.sub {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 10%;
      }
    }

    b {
      font-weight: 600;
    }

    caption {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    * {
      scrollbar-width: thin;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    *::-webkit-scrollbar {
      width: 6px;
      height: 8px;
    }

    *::-webkit-scrollbar-track {
      background: transparent;
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border: 3px solid transparent;
      background: transparent;
      
      &:hover {
        cursor: pointer;
        background: ${COLORS.whiteLight};
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    .ElementsApp.is-autofilled .InputElement,
    .ElementsApp,
    .InputElement {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s !important;
        -webkit-text-fill-color: #ffffff !important;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s !important;
      -webkit-text-fill-color: #ffffff !important;
    }



    .grecaptcha-badge {
      visibility: hidden;
    }

    .MuiGrid-root.MuiGrid-container {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }
  `,
);
