import { CursesSections } from 'types/common';
import { ActiveFilters } from '../../store/reducers/types/index';

export type OneCourse = {
   id: number,
   rating: number,
   section: CursesSections,
   button: string,
   courseModification: CoursesModifications,
   category: ActiveFilters,
   language: string[],
}

export type CoursesModifications =
   | 'restoreBroken'
   | 'kidsLessonsPrepare'
   | 'oratorMastery'
   | 'kidsExercises'
   | 'parentAndChild'
   | 'artKidsExercises'
   | 'mentalMath'
   | 'urbanHigh'
   | 'urbanTop'
   | 'relationshipGod'
   | 'prepareToSuccess'
   | 'parentsSchool'
   | 'audioAscent'
   | 'audioSand'
   | 'audioForest'
   | 'workWithNegativeEmotions'
   | 'sandTherapyTechnique'
   | 'personalities'
   | 'thematicActivity'
   | 'gameLab'
   | 'creativeRestoration'
   | 'fiveDirections'
   | 'bibleKidsLessons'
   | 'restoreBrokenPremium'
   | 'puzzleShow'
   | 'familyContext'
   | 'urbanShow'
   | 'innovationLeadershipStudio'
   | 'basicsOfConsulting'
   | 'noSelected'
   | 'buckleUp'
   | 'buckleUpProposal'
   | 'enterAltitude'
   | 'moveUpwards'
   | 'chooseDirection'
   | 'maintainBalance'
   // not added in BE
   | 'discoverHorizons'
   | 'onlyMaterials'
   | 'familyWithGod'

export type CoursesLectors =
   | 'volodimirUbeivolc'
   | 'iuliaUbeivolk';

export enum CoursesButtonTypes {
   DESCRIPTION = 'course.description',
   SUBSCRIBE = 'course.subscribe',
   PINTEREST = 'course.pinterest',
   LISTEN = 'course.listen',
   SOON = 'course.soon',
   READ = 'course.read',
   SEE = 'course.see',
   BUY = 'course.buy'
}

export enum LectorsTypes {
   VUBEIVOLC = 'volodimirUbeivolc',
   UUBEIVOLC = 'iuliaUbeivolk',
   OVORONOVA = 'olgaVoronova',
   SMIHAILOV = 'sergheiMihailov',
   VCARAPUNARLI = 'veraCrapunarli',
   SSEMAEV = 'sergheiSemaev',
   VGRECOV = 'vitaliiGrecov',
   PSOZONOVA = 'polinaSozonova',
   AVASILTSOVA = 'annaVasiltsova',
   EVORONOVA = 'ekaterinaVoronova',
   AUBEIVOLC = 'annaUbeivolc',
   SPROCOPOV = 'sergheiProcopov',
   ICIRIAC = 'innaCiriac'
}