import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const FilterWrapper = styled.div`
   width: auto;
   height: 30px;
   text-align: left;
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 130%;
   text-transform: uppercase;
   align-items: left;
   background: transparent;
   border: 2px solid transparent;
   border-radius: 100px;
   color: ${COLORS.blackLabel};
   padding: 5px 10px;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   opacity: 0.5;

   &.active {
     background: ${COLORS.blackLabel};
     border-color: ${COLORS.blackLabel};
     color: ${COLORS.white};
     opacity: 1;
   }

   &:hover {
     border-color: ${COLORS.blackLabel};
   }
`;

export const ExpendedFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArrowUp = styled.img`
  transform: rotate(180deg);
`;

export const ExpendedModal = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalContent = styled.div<{left?: number, top?: number}>`
  position: absolute;
  top: 35%;
  left: 35%;
  display: flex;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: column;
  gap: 10px;
  background: ${COLORS.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.35);
  z-index: 100;
`;