import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import News from './News/News';
import PopularCourses from './PopularCourses/PopularCourses';
import Directions from './Directions/Directions';
import Team from './Team/Team';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import Games from './Games/Games';
import Calendar from './Calendar/Calendar';

import { MainWrapper, MainTopRow, MainBottomRow, RowWrapper } from './styled';
import { changeActiveTab } from '../../../store/actions/appActions';


const Main = () => {
   const { isMobile, width } = useMediaQuery();
   const dispatch = useDispatch()

   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'main' }))
   }, [])

   return (
      <MainWrapper isMobile={isMobile}>
         <MainTopRow isMobile={isMobile}>
            <PopularCourses />
         </MainTopRow>
         <MainBottomRow isMobile={isMobile}>
            <Directions />
            <RowWrapper isSmallScreen={width < 1800}>
               {!isMobile && <Team />}
               <div>
                  {width > 1450 && <Calendar />}
                  {width > 1800 && <Games />}
               </div>
            </RowWrapper>
         </MainBottomRow>
      </MainWrapper>
   );
}

export default Main;