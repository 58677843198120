
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const AllCoursesContainer = styled.div<{isMobile: boolean}>`
   display: block;
   margin: 20px;
   z-index: 1;
   height: calc(100vh - ${({isMobile}) => isMobile ? '140px' : '40px'});
   overflow-y: scroll;
`;

export const CoursesWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   flex-wrap: wrap;
   width: 90vw;
`;

export const OneCourseWrapper = styled.div`
   width: 270px;
   margin-right: 10px;
   margin-bottom: 20px;
`;

export const RecommendedWrapper = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   margin-bottom: 20px;
   width: 100%;
`;

export const RecommendedSection = styled.div`
   width: 100%;
`;

export const TitleRowWrapper = styled.div<{isMobile: boolean}>`
   display: flex
   justify-content: space-between;
   width: ${({isMobile}) => isMobile ? 'calc(100vw - 50px)' : 'calc(100vw - 250px)'};
   align-items: center;
   overflow: hidden;
   position: ${({isMobile}) => isMobile ? 'unset' : 'sticky'};
   top: 0;
   background-color: ${COLORS.urbanBaGLight};
   z-index: 2;
`;

export const TitlePremiumRowWrapper = styled.div<{isMobile: boolean}>`
   display: flex
   width: ${({isMobile}) => isMobile ? 'calc(100vw - 50px)' : 'calc(100vw - 250px)'};
   align-items: center;
   overflow: hidden;
   position: ${({isMobile}) => isMobile ? 'unset' : 'sticky'};
   top: 0;
   background-color: ${COLORS.urbanBaGLight};
   z-index: 2;

   h1 {
      margin-right: 15px;
   }
`;