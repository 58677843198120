import styled from 'styled-components';

import { COLORS } from '../../../constants/colors';

export const LeftMenuWrapper = styled.div<{ isOpened: boolean, width: number, isMobile: boolean }>`
   display: flex;
   justify-content: space-around;
   width: ${({ isOpened, width, isMobile }) => isOpened ? `${isMobile ? width : 400}px` : '50px'};
   position: absolute;
   top: 0px;
   right: 0px;
   height: ${({ isMobile }) => isMobile ? 'calc(100vh - 130px)' : '100%'};
   background-color: ${({ isMobile, isOpened }) => isMobile ? `${isOpened ? COLORS.urbanBlack: 'transparent' }` :COLORS.urbanBlack };
   align-items: center;
   text-align: center;
   overflow: hidden;
   z-index: 2;
   transition: width 0.5s;
`

export const LeftMenuControllerWrapper = styled.div<{ isMobile?: boolean, isLeftMenuOpened: boolean }>`
   width: 60px;
   height: 100vh;
   z-index: 2;
   user-select: none;
   border-right: ${({ isLeftMenuOpened }) => isLeftMenuOpened ? '1px solid rgba(166, 166, 166, 0.13)' : 'none'};
   min-width: 50px;

   &:hover {
      cursor: pointer;
   }
`

export const OpenMenuIcon = styled.img`
   width: 20px;
   margin-right: 4px;
   margin-left: 4px;
   margin-top: 50vh;
   height: 20px;
`

export const CloseMenuIcon = styled.img`
   width: 20px;
   margin-right: 4px;
   margin-left: 4px;
   margin-top: 50vh;
   height: 20px;
   transform: rotate(180deg);
`

export const ContentWrapper = styled.div<{ isMobile?: boolean }>`
   width: 86%;
   background-color: ${COLORS.urbanBlack};
`