import styled from 'styled-components';
import { hexToRGB } from '../../../utils/colorConverter';
import { COLORS } from '../../../constants/colors';


type Props = {
  prefixContent?: boolean;
  suffixContent?: boolean;
  isIcon?: boolean;
};

export const Input = styled.div<Props>`
  position: relative;

  .icon {
    position: absolute;
    left: 12px;
    margin-top: 10px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    transition: ease 0.2s;
    padding: 0 20px;
    padding-left: ${({ prefixContent, isIcon }) => (prefixContent || isIcon ? '40px' : '20px')};
    padding-right: ${({ suffixContent }) => (suffixContent ? '30px' : '20px')};
    box-sizing: border-box;

    &:focus {
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;

      & ~ .prefix,
      & ~ .suffix {
        opacity: 1;
      }
    }
  }

  .primary {
    height: 45px;
    border-radius: 30px;
    font-size: 14px;
    background: ${COLORS.inputBG};
    color: ${COLORS.white};
    border: 2px solid ${COLORS.inputBG};

    &:focus {
      border-color: ${COLORS.white};

      &.color-Event-lab {
        border: 2px solid ${COLORS.eventLab};
      }

      &.color-Art-therapy {
        border: 2px solid ${COLORS.artTherapy};
      }

      &.color-Kids {
        border: 2px solid ${COLORS.kids};
      }

      &.color-Teens {
        border: 2px solid ${COLORS.teens};
      }

      &.color-Youth {
        border: 2px solid ${COLORS.youth};
      }

      &.color-Show {
        border: 2px solid ${COLORS.show};
      }

      &.color-Custom {
        border: 2px solid ${COLORS.magenta};
      }

    }

    &.error {
      border: 2px solid ${COLORS.magenta};
    }

    &:disabled,
    [disabled] {
      color: ${COLORS.white};
    }
  }

  .secondary {
    height: 50px;
    background: ${hexToRGB(COLORS.urbanBaGLight, 0.3)};
    border-radius: 10px;
    font-size: 16px;
    color:  ${hexToRGB(COLORS.urbanBaGLight, 0.5)};
    border: 2px solid ${hexToRGB(COLORS.urbanBaGLight, 0.3)};

    &:focus {
      border-color: ${COLORS.white};

      &.color-Event-lab {
        border: 2px solid ${COLORS.eventLab};
      }

      &.color-Art-therapy {
        border: 2px solid ${COLORS.artTherapy};
      }

      &.color-Kids {
        border: 2px solid ${COLORS.kids};
      }

      &.color-Teens {
        border: 2px solid ${COLORS.teens};
      }

      &.color-Youth {
        border: 2px solid ${COLORS.youth};
      }

      &.color-Show {
        border: 2px solid ${COLORS.show};
      }
    }

    &:disabled,
    [disabled] {
      color: ${COLORS.white};
    }
  }

  .search {
    height: 45px;
    background: ${COLORS.white};
    border-radius: 30px;
    font-size: 14px;
    color: ${COLORS.urbanBlack};
    border: 2px solid ${COLORS.darkUrban};

    &:focus {
      border-color: ${COLORS.urbanBlack};
    }
  }

  label {
    font-size: 16px;
    color: ${COLORS.urbanBlack};
    margin-bottom: 17px;
    display: block;
  }
`;
