
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import cabinetBGwhite from '../../../assets/global/cabinetBGwhite.png';
import { NAVIGATION_WIDTH } from '../../../constants/dimensions';

export const CabinetWrapper = styled.div<{ isMobile: boolean }>`
   align-items: flex-start;
   width: calc(100vw - ${({ isMobile }) => isMobile ? 0 : NAVIGATION_WIDTH - 10}px);
   height: ${({ isMobile }) => isMobile ? 'auto' : 'calc(100vh - 20px)'}};
   color: ${COLORS.white};
   background-image: url(${cabinetBGwhite});
   background-repeat: no-repeat;
   background-size: cover;
`;

export const CabinetTopRow = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
`;

export const CabinetBottomRow = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
   height: ${({ isMobile }) => isMobile ? 'auto' : 'calc(100vh - 550px)'}};
`;