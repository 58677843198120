import styled from 'styled-components';

import { COLORS } from '../../../constants/colors';

export const FirstTimeTooltipWrapper = styled.div<{ isMobile: boolean }>`
   position: absolute;
   top: 50px;
   right: 70px;
   width: ${({ isMobile }) => isMobile ? '190px' : '260px'}};
   height: ${({ isMobile }) => isMobile ? '100px' : '140px'}};
   font-size: ${({ isMobile }) => isMobile ? '13px' : '16px'}};
   line-height: ${({ isMobile }) => isMobile ? '1.2' : '1.8'}};
   border-radius: 5px;
   padding: 5px 4px 4px 20px;
   text-align: center;
   display: flex;
   justify-content: center;
   flex-direction: column;
   background-color: ${COLORS.blackLabel};
   color: ${COLORS.white};

   button {
      margin-top: 5px;
      width: ${({ isMobile }) => isMobile ? '160px' : '230px'} !important;
      height: ${({ isMobile }) => isMobile ? '40px' : '40px'} !important;
   }

`

export const FirstTimeTooltipHolder = styled.div<{ isMobile: boolean }>`
   img {
      position: absolute;
      top: ${({ isMobile }) => isMobile ? '160px' : '210px'};
      right: 0px;
      width: ${({ isMobile }) => isMobile ? '160px' : '260px'};
      height: 140px;
      user-select: none;
      z-index: 1;
   }
`;

export const StarTooltipHolder = styled.div<{ isMobile: boolean }>`
   img {
      position: absolute;
      bottom: ${({ isMobile }) => isMobile ? '210px' : '70px'};
      left: 0px;
      width: ${({ isMobile }) => isMobile ? '10px' : '60px'};
      height: ${({ isMobile }) => isMobile ? '10px' : '60px'};
      user-select: none;
      z-index: 1;
   }
`;

export const StarTooltipWrapper = styled.div<{ isMobile: boolean }>`
   position: absolute;
   bottom: ${({ isMobile }) => isMobile ? '70px' : '10px'};
   left: 10px;
   width: ${({ isMobile }) => isMobile ? '30px' : '60px'};
   height: ${({ isMobile }) => isMobile ? '30px' : '60px'};
   border-radius: 55px;
   border: ${({ isMobile }) => isMobile ? '0px' : '8px'} solid ${COLORS.buttonSecondaryBG};
   padding: ${({ isMobile }) => isMobile ? '3px' : '10px'};
   text-align: center;
   display: flex;
   justify-content: center;
   flex-direction: column;
   background-color: ${COLORS.buttonSecondaryBG};
   color: ${COLORS.white};

   &:hover {
      cursor: pointer;
      border: ${({ isMobile }) => isMobile ? '0px' : '8px'} solid ${COLORS.buttonSecondaryBGActive};
   }
`