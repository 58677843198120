import { useDispatch, useSelector } from 'react-redux';

import { setLeftMenu, setUserFirstTime } from '../../../store/actions/bookActions';
import { selectLeftMenuState } from '../../../store/selectors/book';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import LeftMenuController from './LeftMenuController';

import {
   LeftMenuWrapper,
   ContentWrapper
} from './styled';

type Props = {
   children: React.ReactNode
}

const LeftMenu = ({ children }: Props) => {
   const isLeftMenuOpened = useSelector(selectLeftMenuState)
   const dispatch = useDispatch();
   const { isMobile, width } = useMediaQuery();

   const toggleLeftMenu = () => {
      dispatch(setLeftMenu({ isLeftMenuOpen: !isLeftMenuOpened }))
      dispatch(setUserFirstTime({ isUserFirstTime: false }))
   }


   return (
      <LeftMenuWrapper isMobile={isMobile} width={width} isOpened={isLeftMenuOpened}>
         <LeftMenuController isLeftMenuOpened={isLeftMenuOpened} toggleLeftMenu={toggleLeftMenu} />
         {isLeftMenuOpened && <ContentWrapper>
            {children}
         </ContentWrapper>}
      </LeftMenuWrapper>
   );
};

export default LeftMenu;