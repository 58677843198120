import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CircularProgress from '@mui/material/CircularProgress';
import { changeActiveTab, setCurrentCourse } from '../../../../store/actions/appActions';
import { COURSES, SECTIONS } from '../../../../constants/courses';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import CoursePreview from '../CommonComponents/CoursePreview';
import maintainBalance from '../../../../assets/courses/previewCourses/maintainBalance.png';
import { CursesSections } from '../../../../types/common';
import oneLessonNew from '../../../../assets/courses/maintainBalance/oneLessonNew.png';
import oneLesson from '../../../../assets/courses/maintainBalance/oneLesson.png';
import chatDecoration from '../../../../assets/courses/buckleUp/chatDecoration.png';
import ContactsMap from '../CommonComponents/ContactsMap';
import SideModal from '../../../UI/Modal/SideModal';
import { COLORS } from '../../../../constants/colors';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import PaymentSteps from '../../../PaymentSteps/PaymentSteps';
import { selectUserProfile } from '../../../../store/selectors/user';
import { Button } from '../../../UI/Button';
import Puzzle1 from '../../../../assets/courses/mentalMath/pazzle1.svg';
import Puzzle2 from '../../../../assets/courses/mentalMath/pazzle2.svg';
import Puzzle3 from '../../../../assets/courses/mentalMath/pazzle3.svg';
import Puzzle4 from '../../../../assets/courses/mentalMath/pazzle4.svg';

import { downloadMaterials } from '../../../../utils/courses/downloadMaterials';
import {
   RestoreWrapper,
   CourseTitle,
   CoursePlanWrapper,
   ChatDecorationWrapper,
   ChatSubtitle,
   ChatTitle,
   ButtonWrapper,
   PuzzleContainer,
   GridItem1,
   GridItem2,
   GridItem4,
   GridItem5,
   GridItem8,
   PuzzleImg,
   PuzzleText,
   GridItemPuzzle,
   CourseSubtitle,
   CourseParagraphTitle
} from './styles';
import { getTransactionId } from '../../../../store/actions/userActions';

const COURSE_PAYMENT_AMOUNT = 80000;

const MaintainBalanceProposal = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const navigate = useNavigate();
   const { isMobile, width } = useMediaQuery()
   const userProfileInfo = useSelector(selectUserProfile)
   const { enqueueSnackbar } = useSnackbar();
   const hasUserPaid = userProfileInfo?.premium_courses?.includes(COURSES.maintainBalance)
   const [isDownloadingDone, setIsDownloadDone] = useState(false);
   const [isDownloading, setIsDownloading] = useState(false);


   const PuzzleComponent = ({ title, subtitle, decoration }: { title: string; subtitle: string; decoration: string }) => {
      return (
         <GridItemPuzzle decoration={decoration}>
            <CourseParagraphTitle>{t(`course.${COURSES.maintainBalanceProposal}.${title}`)}</CourseParagraphTitle>
            <CourseSubtitle>{t(`course.${COURSES.maintainBalanceProposal}.${subtitle}`)}</CourseSubtitle>
         </GridItemPuzzle>
      );
   };

   const handleButtonClick = () => {
      if (hasUserPaid) {
         dispatch(changeActiveTab({ activeTab: 'cabinet' }))
         dispatch(setCurrentCourse({ courseModification: COURSES.maintainBalance as CoursesModifications }))
         navigate('/cabinet')
      } else {
         handleOpenModal()
      }
   }

   const handleOpenModal = () => {
      if (userProfileInfo?.email) {
         dispatch(getTransactionId({ amount: COURSE_PAYMENT_AMOUNT }) as any)
         setIsModalOpen(!isModalOpen);
      } else {
         enqueueSnackbar(t('course.moveUpwardsProposal.modalError'), {
            variant: 'warning', anchorOrigin: {
               vertical: 'top',
               horizontal: 'left'
            }
         })
      }
   }

   const handleCloseModal = () => {
      setIsModalOpen(false);
   }

   const handleMaterialsClick = () => {
      setIsDownloading(true);
      return downloadMaterials("https://s3.amazonaws.com/urbancenter.md/maintainBalance/lesson3/lesson3.pdf", ["Lesson_demo.pdf"], () => {
         setIsDownloading(false)
         setIsDownloadDone(true)
      })
   }

   const renderDownloadButton = () => {
      if (isDownloading) {
         return <CircularProgress size={20} color='secondary' />
      }
      if (isDownloadingDone) {
         return <DownloadDoneIcon fontSize='small' />
      } else {
         return t(`course.maintainBalanceProposal.demoLesson`)
      }
   }


   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'cabinet' }))
      if (hasUserPaid) {
         dispatch(setCurrentCourse({ courseModification: COURSES.maintainBalance as CoursesModifications }))
         return
      }
      dispatch(setCurrentCourse({ courseModification: COURSES.notSelected as CoursesModifications }))
   }, []);

   return (
      <RestoreWrapper>
         <CoursePreview
            bgImage={maintainBalance}
            sectionName={SECTIONS.teens as CursesSections}
            modification={COURSES.maintainBalanceProposal as CoursesModifications}
            button={hasUserPaid ? CoursesButtonTypes.READ : CoursesButtonTypes.BUY}
            buttonModification={'primaryOutlined'}
            customTag={'50$ / 800 MDL'}
            handleButtonClick={handleButtonClick}
         />
         <CourseTitle>
            {t('course.maintainBalanceProposal.howTo')}
         </CourseTitle>
         <CoursePlanWrapper isMobile={isMobile}>
            <img src={oneLesson} alt="course plan" />
            <div>
               <CourseTitle>
                  {t(`course.${COURSES.maintainBalanceProposal}.lessonTitle`)}
               </CourseTitle>
               <p>
                  {t(`course.${COURSES.maintainBalanceProposal}.lessonSubtitle`)}
               </p>
            </div>
         </CoursePlanWrapper>
         <CoursePlanWrapper isMobile={isMobile}>
            <div>
               <CourseTitle>
                  {t(`course.${COURSES.maintainBalanceProposal}.lessonTitleTwo`)}
               </CourseTitle>
               <p>
                  {t(`course.${COURSES.maintainBalanceProposal}.lessonSubtitleTwo`)}
               </p>
            </div>
            <img src={oneLessonNew} alt="course plan" />
         </CoursePlanWrapper>
         <ChatDecorationWrapper isMobile={isMobile} isSmallScreen={width < 1400}>
            <ChatTitle isMobile={isMobile} isSmallScreen={width < 1400}>
               {t(`course.${COURSES.maintainBalanceProposal}.includedTitle`)}
            </ChatTitle>
            <ChatSubtitle isMobile={isMobile} isSmallScreen={width < 1400}>
               <div>
                  1: {t(`course.${COURSES.maintainBalanceProposal}.includedDescriptionOne`)}
               </div>
               <div>
                  2: {t(`course.${COURSES.maintainBalanceProposal}.includedDescriptionTwo`)}
               </div>
               <div>
                  3: {t(`course.${COURSES.maintainBalanceProposal}.includedDescriptionThree`)}
               </div>
               <div>
                  4: {t(`course.${COURSES.maintainBalanceProposal}.includedDescriptionFour`)}
               </div>
               <div>
                  5: {t(`course.${COURSES.maintainBalanceProposal}.includedDescriptionFive`)}
               </div>
            </ChatSubtitle>
            <img src={chatDecoration} alt="" />
            <ButtonWrapper isMobile={isMobile} isSmallScreen={width < 1400}>
               <Button onClick={handleButtonClick} modifier='primaryOutlined' color={SECTIONS.teens as CursesSections}>{t(`course.buy`)}</Button>
               <Button
                  onClick={handleMaterialsClick}
                  modifier='primaryOutlined'
                  color={SECTIONS.teens as CursesSections}
               >
                  {renderDownloadButton()}
               </Button>
            </ButtonWrapper>
         </ChatDecorationWrapper>
         <div>
            <CourseTitle>{t(`course.${COURSES.maintainBalanceProposal}.buildFutureTitle`)}</CourseTitle>
            <PuzzleContainer isMobile={isMobile}>
               <GridItem1 isMobile={isMobile}>
                  <PuzzleText>{t(`course.${COURSES.maintainBalanceProposal}.buildFutureSubtitle`)}</PuzzleText>
               </GridItem1>
               <GridItem2>
                  <PuzzleImg src={Puzzle2} isMobile={isMobile}></PuzzleImg>
               </GridItem2>
               <PuzzleComponent title='thirdPuzleTitle' subtitle='thirdPuzleDescription' decoration='three' />
               <GridItem4 isMobile={isMobile}>
                  <PuzzleImg src={Puzzle4} isMobile={isMobile}></PuzzleImg>
               </GridItem4>
               <GridItem5>
                  <PuzzleImg src={Puzzle1} isMobile={isMobile}></PuzzleImg>
               </GridItem5>
               <PuzzleComponent title='firstPuzleTitle' subtitle='firstPuzleDescription' decoration='one' />
               <PuzzleComponent title='secondPuzleTitle' subtitle='secondPuzleDescription' decoration='two' />
               <GridItem8>
                  <PuzzleImg src={Puzzle3} isMobile={isMobile}></PuzzleImg>
               </GridItem8>
               <PuzzleComponent title='fourthPuzleTitle' subtitle='fourthPuzleDescription' decoration='four' />
            </PuzzleContainer>
         </div>
         <ContactsMap handleButtonClick={handleOpenModal} />
         <SideModal
            open={isModalOpen}
            handleClose={handleCloseModal}
            bgcolor={COLORS.courseCardBackground}
            modalWidth={500}
            height={500}
         >
            <PaymentSteps
               isMaib={true}
               modification={SECTIONS.teens as CursesSections}
               course={COURSES.maintainBalanceProposal as CoursesModifications}
               handleCloseModal={handleCloseModal}
            />
         </SideModal>
      </RestoreWrapper>
   );
};

export default MaintainBalanceProposal;