
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { NAVIGATION_WIDTH } from '../../../constants/dimensions';

export const BoorReaderWrapper = styled.div<{ isMobile: boolean, width: number }>`
   width:  ${({ isMobile, width }) => isMobile ? `${width}px` : `calc(${width}px - ${NAVIGATION_WIDTH}px)`};
   text-align: center;
   overflow: hidden;
   background-color: ${COLORS.white};
`;

export const OpenMenuIcon = styled.img<{ isMobile: boolean }>`
   margin-top: ${({ isMobile }) => isMobile ? '6px' : '15px'}};
   width: ${({ isMobile }) => isMobile ? '4px' : '8px'}};
   margin-left: -5px;
   height: ${({ isMobile }) => isMobile ? '14px' : '27px'}};
`

export const CloseMenuIcon = styled.img<{ isMobile: boolean }>`
   margin-top: ${({ isMobile }) => isMobile ? '6px' : '15px'}};
   width: ${({ isMobile }) => isMobile ? '4px' : '8px'}};
   margin-left: ${({ isMobile }) => isMobile ? '-2px' : '-5px'}};
   height: ${({ isMobile }) => isMobile ? '14px' : '27px'}};
   transform: rotate(180deg);
`

export const BookContentHolder = styled.div<{ isMobile: boolean }>`
   width: 100%;
   overflow: hidden;
   display: flex;
   justify-content: center;

   .react-pdf__Document {
      .react-pdf__message {
         background-color: ${COLORS.white};
         height: ${({ isMobile }) => isMobile ? '85.9vh' : '93.9vh'} !important;
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
         font-size: 20px;
         font-weight: 700;
      }
   }

   .react-pdf__message--error {
      display: none !important;
   }
   
   .react-pdf__Page__svg {
      height: ${({ isMobile }) => isMobile ? 'auto' : '93.9vh'} !important;
      width: 100% !important;
      svg {
         height: ${({ isMobile }) => isMobile ? '85.9vh' : '93.9vh'} !important;
         width:  ${({ isMobile }) => isMobile ? '100vw' : '40vw'} !important;;
      }

     
   }

   .react-pdf__Page__canvas {
      height: ${({ isMobile }) => isMobile ? '85.9vh' : '93.9vh'} !important;
      width: 100% !important;

      canvas {
         height: ${({ isMobile }) => isMobile ? '85.9vh' : '93.9vh'} !important;
         width: 100% !important;
      }
   }

   .react-pdf__Page__textContent {
      display: none;
   }

   .react-pdf__Page__annotations {
      display: none;
   }
`
export const PageFlipper = styled.div<{ isMobile: boolean }>`
   position: absolute;
   display: flex;
   bottom: ${({ isMobile }) => isMobile ? '65px' : '20px'}};
   text-align: center;
   left: 45%;
   background-color: ${COLORS.white};
   height: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   width: ${({ isMobile }) => isMobile ? '50px' : '110px'}};
   filter: drop-shadow(12px 12px 22px rgba(0, 0, 0, 0.25));
   border-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
`

export const PrevPage = styled.div<{ isAvailable: boolean, isMobile: boolean }>`
   height: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   width: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   align-items: center;
   text-align: center;
   border-right: 1px solid ${COLORS.whiteLight};
   border-top-left-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
   border-bottom-left-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
   background-color: ${({ isAvailable }) => isAvailable ? COLORS.white : COLORS.whiteLight};

   &:hover {
      background-color: ${COLORS.whiteLight};
      cursor: ${({ isAvailable }) => isAvailable ? 'pointer' : 'not-allowed'};
   }

`

export const NextPage = styled.div<{ isAvailable: boolean, isMobile: boolean }>`
   height: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   width: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   align-items: center;
   text-align: center;
   border-top-right-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
   border-bottom-right-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
   background-color: ${({ isAvailable }) => isAvailable ? COLORS.white : COLORS.whiteLight};

   &:hover {
      background-color: ${COLORS.whiteLight};
      cursor: ${({ isAvailable }) => isAvailable ? 'pointer' : 'not-allowed'};

   }
`

export const BookPlanHolder = styled.div<{ isMobile: boolean }>`
   width: ${({ isMobile }) => isMobile ? 'calc(100% - 20px)' : '325px'};
   overflow: hidden;
`;

export const BookPlanTitle = styled.div<{ isMobile: boolean }>`
   display: flex;
   justify-content: space-between;
   align-items: center;

   .chapters {
      color: ${COLORS.white};
      opacity: 0.5;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: fit-content;

      img {
         margin-right: 5px;
      }
   }

   h1 {
      margin-left: 20px;
      margin-top: 20px;
      font-size: 14px;
      line-height: ${({ isMobile }) => isMobile ? '12px' : '28px'};
      color: ${COLORS.white};
   }
`;

export const OneDescriptionElementWrapper = styled.div<{ isCurrent: boolean, isMobile: boolean, color: string }>`
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 171%;
   padding: 10px;
   background-color:${({ isCurrent, color }) => isCurrent ? color : COLORS.darkUrban};
   color: ${({ isCurrent }) => isCurrent ? COLORS.white : COLORS.white};
   border: ${({ isCurrent }) => isCurrent ? `2px solid ${COLORS.white}` : `2px solid ${COLORS.blackLabel}`};
   cursor: pointer;
   margin-left: ${({ isMobile }) => isMobile ? '20px' : '20px'};
   margin-top: ${({ isMobile }) => isMobile ? '10px' : '10px'};
   margin-bottom: ${({ isMobile }) => isMobile ? '10px' : '10px'};
   border-radius: 5px;
   transition: all 0.2s ease-in-out;
   text-align: left;

   &:hover {
      ${({ isMobile }) => isMobile ? '' : `background-color: ${COLORS.blackLabel} `}; 
      color: ${COLORS.white};
      border: 2px solid ${COLORS.white};
      cursor: pointer;
   }

`;

export const BookPlanWrapper = styled.div<{ isMobile: boolean, isMaterials: boolean }>`
   overflow: scroll;
   overflow-x: hidden;
   margin-bottom: 10px;
   height: ${({ isMobile, isMaterials }) => isMobile ? `calc(100vh - ${isMaterials ? 220 : 190}px)` : `calc(100vh - ${isMaterials ? 190 : 140}px)`};
`;

export const Divider = styled.div<{ isMobile: boolean }>`
   width: ${({ isMobile }) => isMobile ? 'calc(100vw - 40px)' : '305px'};
   height: 1px;
   background-color: ${COLORS.white};
   margin-bottom: 4px;
   margin-left: 20px;
   margin-bottom: 5px;
   opacity: 0.1;
`;


export const BookDownload = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   color: ${COLORS.white};
   height: 70px;
   margin-bottom: 50px;

   button {
      width: 40px !important;
      height: 40px !important;
      margin-right: 20px;
      margin-bottom: 10px;
   }

   h1 {
      margin-left: 20px;
      margin-top: 9px;
      font-size: 14px;
      line-height: ${({ isMobile }) => isMobile ? '12px' : '28px'};
   }
`;