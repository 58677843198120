import { useTranslation } from 'react-i18next';

import { CursesSections } from '../../../types/common';
import MaterialModal from '../Modal/MaterialModal';
import { COLORS } from '../../../constants/colors';

import styled from 'styled-components';
import { Button } from '../Button';
import { TranslateType, changeTranslation } from '../../../store/actions/translateAction';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../../../store/selectors/user';
import { updateTranslation } from '../../../store/actions/userActions';

const BookLanguageTooltip = ({ section, isModalOpen, setIsModalOpen }: 
   { section: CursesSections, isModalOpen: boolean, setIsModalOpen: (value: boolean) => void  }) => {
   const { i18n } = useTranslation();
   const dispatch = useDispatch();
   const userProfile = useSelector(selectUserProfile);
   const isUserLoggedIn = !!userProfile?.id;
   const handleOpenModal = () => {
      setIsModalOpen(!isModalOpen);
   }

   const changeLanguage = (lng: TranslateType) => {
      if (isUserLoggedIn) {
         dispatch(updateTranslation({ newLanguage: lng, i18n }) as any)
         handleOpenModal()
      } else {
         dispatch(changeTranslation({ newLanguage: lng }));
         handleOpenModal()
      }
   };

   return (
      <>
         <MaterialModal
            modification={section}
            open={isModalOpen}
            handleClose={handleOpenModal}
            bgcolor={COLORS.courseCardBackground}
            title={'RU / RO'}
            width={400}
            height={200}
         >
            <Title>
               Slectati limba / Выберите язык
            </Title>
            <CooseLanguage>
               <Button onClick={() => changeLanguage('ru')} modifier='primary'>RU</Button>
               <Button onClick={() => changeLanguage('ro')} modifier='primary'>RO</Button>
            </CooseLanguage>
         </MaterialModal>
      </>
   );
};

export default BookLanguageTooltip;


export const CooseLanguage = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0px 20px;
   cursor: pointer;
   max-width: 200px;
   gap: 10px;

   button {
      width: 50px !important;
      padding: 10px 0;
   }
`;

export const Title = styled.h2`
   padding: 10px 20px;
   font-size: 22px;
   font-weight: 500;
   color: ${COLORS.white}
`;