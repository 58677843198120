import { COURSES, LECTORS } from '../../constants/courses';

import volodimirUbeivolc from '../../assets/team/volodimirUbeivolc.png';
import olgaVoronova from '../../assets/team/olgaVoronova.png'
//import sergheiMihailov from '../../assets/team/sergheiMihailov.png';
import iuliaUbeivolk from '../../assets/team/iuliaUbeivolk.png';
import veraCrapunarli from '../../assets/team/veraCrapunarli.png';
import sergheiSemaev from '../../assets/team/sergheiSemaev.png';
import vitaliiGrecov from '../../assets/team/vitaliiGrecov.png';
import polinaSozonova from '../../assets/team/polinaSozonova.png';
import annaVasiltsova from '../../assets/team/annaVasiltsova.png';
import ekaterinaVoronova from '../../assets/team/ekaterinaVoronova.png';
import annaUbeivolc from '../../assets/team/annaUbeivolc.png';
import sergheiProcopov from '../../assets/team/sergheiProcopov.png';
import innaCiriac from '../../assets/team/innaCiriac.png';
import urbanUser from '../../assets/courses/courseUsers/urbanUser.png';

import { CoursesModifications } from 'types/courses/courses';



export type CourseLector = {
  name: string;
  position: string;
  longDescription: string;
  quote: string;
  experience: string;
  photo?: string;
}

export const getCourseLector = (courseModification: CoursesModifications): CourseLector => {
  switch (courseModification) {
    case COURSES.oratorMastery:
      return {
        name: `lectors.${LECTORS.volodimirUbeivolc}.name`,
        position: `lectors.${LECTORS.volodimirUbeivolc}.position`,
        longDescription: `lectors.${LECTORS.volodimirUbeivolc}.longDescription`,
        quote: `lectors.${LECTORS.volodimirUbeivolc}.quote`,
        experience: `lectors.${LECTORS.volodimirUbeivolc}.experience`,
        photo: volodimirUbeivolc
      };
    case COURSES.kidsExercises:
      return {
        name: `lectors.${LECTORS.innaCiriac}.name`,
        position: `lectors.${LECTORS.innaCiriac}.position`,
        longDescription: `lectors.${LECTORS.innaCiriac}.longDescription`,
        quote: `lectors.${LECTORS.innaCiriac}.quote`,
        experience: `lectors.${LECTORS.innaCiriac}.experience`,
        photo: innaCiriac
      };
    case COURSES.parentAndChild:
      return {
        name: `lectors.${LECTORS.olgaVoronova}.name`,
        position: `lectors.${LECTORS.olgaVoronova}.position`,
        longDescription: `lectors.${LECTORS.olgaVoronova}.longDescription`,
        quote: `lectors.${LECTORS.olgaVoronova}.quote`,
        experience: `lectors.${LECTORS.olgaVoronova}.experience`,
        photo: olgaVoronova
      };
    case COURSES.restoreBroken:
      return {
        name: `lectors.${LECTORS.iuliaUbeivolk}.name`,
        position: `lectors.${LECTORS.iuliaUbeivolk}.position`,
        longDescription: `lectors.${LECTORS.iuliaUbeivolk}.longDescription`,
        quote: `lectors.${LECTORS.iuliaUbeivolk}.quote`,
        experience: `lectors.${LECTORS.iuliaUbeivolk}.experience`,
        photo: iuliaUbeivolk
      };
    case COURSES.restoreBrokenPremium:
        return {
          name: `lectors.${LECTORS.iuliaUbeivolk}.name`,
          position: `lectors.${LECTORS.iuliaUbeivolk}.position`,
          longDescription: `lectors.${LECTORS.iuliaUbeivolk}.longDescription`,
          quote: `lectors.${LECTORS.iuliaUbeivolk}.quote`,
          experience: `lectors.${LECTORS.iuliaUbeivolk}.experience`,
          photo: iuliaUbeivolk
        };
    case COURSES.kidsLessonsPrepare:
      return {
        name: `lectors.${LECTORS.annaUbeivolc}.name`,
        position: `lectors.${LECTORS.annaUbeivolc}.position`,
        longDescription: `lectors.${LECTORS.annaUbeivolc}.longDescription`,
        quote: `lectors.${LECTORS.annaUbeivolc}.quote`,
        experience: `lectors.${LECTORS.annaUbeivolc}.experience`,
        photo: annaUbeivolc
      };
    case COURSES.artKidsExercises:
      return {
        name: `lectors.${LECTORS.annaUbeivolc}.name`,
        position: `lectors.${LECTORS.annaUbeivolc}.position`,
        longDescription: `lectors.${LECTORS.annaUbeivolc}.longDescription`,
        quote: `lectors.${LECTORS.annaUbeivolc}.quote`,
        experience: `lectors.${LECTORS.annaUbeivolc}.experience`,
        photo: annaUbeivolc
      };
    case COURSES.mentalMath:
      return {
        name: `lectors.${LECTORS.ekaterinaVoronova}.name`,
        position: `lectors.${LECTORS.ekaterinaVoronova}.position`,
        longDescription: `lectors.${LECTORS.ekaterinaVoronova}.longDescription`,
        quote: `lectors.${LECTORS.ekaterinaVoronova}.quote`,
        experience: `lectors.${LECTORS.ekaterinaVoronova}.experience`,
        photo: ekaterinaVoronova
      };
    case COURSES.audioAscent:
      return {
        name: `lectors.${LECTORS.annaVasiltsova}.name`,
        position: `lectors.${LECTORS.annaVasiltsova}.position`,
        longDescription: `lectors.${LECTORS.annaVasiltsova}.longDescription`,
        quote: `lectors.${LECTORS.annaVasiltsova}.quote`,
        experience: `lectors.${LECTORS.annaVasiltsova}.experience`,
        photo: annaVasiltsova
      };
    case COURSES.audioSand:
      return {
        name: `lectors.${LECTORS.annaVasiltsova}.name`,
        position: `lectors.${LECTORS.annaVasiltsova}.position`,
        longDescription: `lectors.${LECTORS.annaVasiltsova}.longDescription`,
        quote: `lectors.${LECTORS.annaVasiltsova}.quote`,
        experience: `lectors.${LECTORS.annaVasiltsova}.experience`,
        photo: annaVasiltsova
      };
    case COURSES.audioForest:
      return {
        name: `lectors.${LECTORS.annaVasiltsova}.name`,
        position: `lectors.${LECTORS.annaVasiltsova}.position`,
        longDescription: `lectors.${LECTORS.annaVasiltsova}.longDescription`,
        quote: `lectors.${LECTORS.annaVasiltsova}.quote`,
        experience: `lectors.${LECTORS.annaVasiltsova}.experience`,
        photo: annaVasiltsova
      };
    case COURSES.workWithNegativeEmotions:
      return {
        name: `lectors.${LECTORS.iuliaUbeivolk}.name`,
        position: `lectors.${LECTORS.iuliaUbeivolk}.position`,
        longDescription: `lectors.${LECTORS.iuliaUbeivolk}.longDescription`,
        quote: `lectors.${LECTORS.iuliaUbeivolk}.quote`,
        experience: `lectors.${LECTORS.iuliaUbeivolk}.experience`,
        photo: iuliaUbeivolk
      };
    case COURSES.sandTherapyTechnique:
      return {
        name: `lectors.${LECTORS.annaVasiltsova}.name`,
        position: `lectors.${LECTORS.annaVasiltsova}.position`,
        longDescription: `lectors.${LECTORS.annaVasiltsova}.longDescription`,
        quote: `lectors.${LECTORS.annaVasiltsova}.quote`,
        experience: `lectors.${LECTORS.annaVasiltsova}.experience`,
        photo: annaVasiltsova
      };
    case COURSES.urbanHigh:
      return {
        name: `lectors.${LECTORS.veraCrapunarli}.name`,
        position: `lectors.${LECTORS.veraCrapunarli}.position`,
        longDescription: `lectors.${LECTORS.veraCrapunarli}.longDescription`,
        quote: `lectors.${LECTORS.veraCrapunarli}.quote`,
        experience: `lectors.${LECTORS.veraCrapunarli}.experience`,
        photo: veraCrapunarli
      };
    case COURSES.urbanTop:
      return {
        name: `lectors.${LECTORS.sergheiSemaev}.name`,
        position: `lectors.${LECTORS.sergheiSemaev}.position`,
        longDescription: `lectors.${LECTORS.sergheiSemaev}.longDescription`,
        quote: `lectors.${LECTORS.sergheiSemaev}.quote`,
        experience: `lectors.${LECTORS.sergheiSemaev}.experience`,
        photo: sergheiSemaev
      };
    case COURSES.relationshipGod:
      return {
        name: `lectors.${LECTORS.sergheiProcopov}.name`,
        position: `lectors.${LECTORS.sergheiProcopov}.position`,
        longDescription: `lectors.${LECTORS.sergheiProcopov}.longDescription`,
        quote: `lectors.${LECTORS.sergheiProcopov}.quote`,
        experience: `lectors.${LECTORS.sergheiProcopov}.experience`,
        photo: sergheiProcopov
      };
    case COURSES.prepareToSuccess:
      return {
        name: `lectors.${LECTORS.sergheiProcopov}.name`,
        position: `lectors.${LECTORS.sergheiProcopov}.position`,
        longDescription: `lectors.${LECTORS.sergheiProcopov}.longDescription`,
        quote: `lectors.${LECTORS.sergheiProcopov}.quote`,
        experience: `lectors.${LECTORS.sergheiProcopov}.experience`,
        photo: sergheiProcopov
      };
    case COURSES.parentsSchool:
      return {
        name: `lectors.${LECTORS.polinaSozonova}.name`,
        position: `lectors.${LECTORS.polinaSozonova}.position`,
        longDescription: `lectors.${LECTORS.polinaSozonova}.longDescription`,
        quote: `lectors.${LECTORS.polinaSozonova}.quote`,
        experience: `lectors.${LECTORS.polinaSozonova}.experience`,
        photo: polinaSozonova
      };
    case COURSES.thematicActivity:
      return {
        name: `lectors.${LECTORS.vitaliiGrecov}.name`,
        position: `lectors.${LECTORS.vitaliiGrecov}.position`,
        longDescription: `lectors.${LECTORS.vitaliiGrecov}.longDescription`,
        quote: `lectors.${LECTORS.vitaliiGrecov}.quote`,
        experience: `lectors.${LECTORS.vitaliiGrecov}.experience`,
        photo: vitaliiGrecov
      };
    case COURSES.gameLab:
        return {
          name: `lectors.${LECTORS.vitaliiGrecov}.name`,
          position: `lectors.${LECTORS.vitaliiGrecov}.position`,
          longDescription: `lectors.${LECTORS.vitaliiGrecov}.longDescription`,
          quote: `lectors.${LECTORS.vitaliiGrecov}.quote`,
          experience: `lectors.${LECTORS.vitaliiGrecov}.experience`,
          photo: vitaliiGrecov
        };
      
    default:
      return {
        name: `lectors.${LECTORS.urbanUser}.name`,
        position: `lectors.${LECTORS.urbanUser}.position`,
        longDescription: `lectors.${LECTORS.urbanUser}.longDescription`,
        quote: `lectors.${LECTORS.urbanUser}.quote`,
        experience: `lectors.${LECTORS.urbanUser}.experience`,
        photo: urbanUser
      };
  }
}