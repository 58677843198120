import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';
import { setUserFirstTime } from '../../../store/actions/bookActions';
import {selectIsUserFirstTime} from '../../../store/selectors/book';
import firstTimeTooltip from '../../../assets/global/arrowToPlan.svg'

import { FirstTimeTooltipWrapper, FirstTimeTooltipHolder } from './styled';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

const FirstTimeTooltip = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const isUserFirstTime = useSelector(selectIsUserFirstTime);
   const { isMobile } = useMediaQuery();

   const handleChangeIsUserFirstTime = () => {
      dispatch(setUserFirstTime({ isUserFirstTime: false }))
   }

   if (!isUserFirstTime) return null;

   return (
      <FirstTimeTooltipHolder isMobile={isMobile}>
         <FirstTimeTooltipWrapper isMobile={isMobile}>
            {t('navigation.book.hereSelector')}
            <Button
               onClick={() => handleChangeIsUserFirstTime()}
               modifier='primaryOutlined'
            > OK </Button>
         </FirstTimeTooltipWrapper>
         <img src={firstTimeTooltip} alt="" />
      </FirstTimeTooltipHolder>
   );
};

export default FirstTimeTooltip;