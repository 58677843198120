import styled from 'styled-components';
import { hexToRGB } from '../../../utils/colorConverter';
import { theme } from '../../../styles/theme';
import { COLORS } from '../../../constants/colors';

export const ButtonWrapper = styled.div`
  button,
  a {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: ease 0.2s;
    border-radius: 10px;
    border: 2px solid;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    white-space: nowrap;
  }

  button.round,
  a.round {
    border-radius: 50%;
  }

  .icon {
    margin-right: 8px;
    margin-left: 12px;
  }

  .dot {
    position: relative;
    top: -9px;
    padding-left: 4px;
  }
  .primary,
  .primary {
    width: 223px;
    height: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    background: ${COLORS.courseCardBackground};
    border: 2px solid ${COLORS.buttonPrimaryBorder};
    border-radius: 100px;
    color: ${COLORS.white};

    &:disabled,
    [disabled] {
      background-color: ${hexToRGB(theme.colors.grey, 0.1)};
      border-color: ${hexToRGB(theme.colors.grey, 0.1)};
      color: ${hexToRGB(theme.colors.grey, 0.5)};
      pointer-events: none;

      &:hover {
        box-shadow: none;
        border-color: ${hexToRGB(theme.colors.grey, 0.1)};
      }
    }
  }

  .transparent {
    background-color: transparent;
    opacity: 0.8;
    color: ${theme.colors.white};
    border-color: transparent;


    &:hover {
      opacity: 1;
    }
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background: ${COLORS.buttonSecondaryBG};
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    padding: 10px;
    color: ${theme.colors.white};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background: ${COLORS.buttonSecondaryBGActive};
      cursor: pointer;
    }
  }

  .secondaryOutlined {
    width: 180px;
    height: 38px;
    border-radius: 100px;
    background: ${COLORS.buttonSecondaryBG};
    border: 2px solid transparent;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: ${theme.colors.white};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;

    img {
      opacity: 0.3;
    }

    &.active {
      background: ${COLORS.buttonSecondaryBGActive};
      img {
        opacity: 0.5;
      }
    }

    &:hover {
      border-color: ${COLORS.white};
      background: ${COLORS.buttonSecondaryBGActive};
      img {
        opacity: 1;
      }
    }
  }

  .primaryOutlined {
    width: 223px;
    height: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    background: ${COLORS.courseCardBackground};
    border: 2px solid ${COLORS.buttonPrimaryBorder};
    border-radius: 100px;
    color: ${COLORS.white};

    &.color-Event-lab {
      background: ${COLORS.eventLab};
      border: 2px solid ${COLORS.eventLab};
    }

    &.color-Art-therapy {
      background: ${COLORS.artTherapy};
      border: 2px solid ${COLORS.artTherapy};
    }

    &.color-Kids {
      background: ${COLORS.kids};
      border: 2px solid ${COLORS.kids};
    }

    &.color-Teens {
      background: ${COLORS.teens};
      border: 2px solid ${COLORS.teens};
    }

    &.color-Youth {
      background: ${COLORS.youth};
      border: 2px solid ${COLORS.youth};
    }

    &.color-Show {
      background: ${COLORS.show};
      border: 2px solid ${COLORS.show};
    }

    &.color-Magenta {
      background: ${COLORS.magenta};
      border: 2px solid ${COLORS.magenta};
    }

    img {
      opacity: 0.3;
    }

    &.active {
      background: ${COLORS.buttonSecondaryBGActive};
      img {
        opacity: 0.5;
      }
    }

    &:hover {
      border-color: ${COLORS.white};
      background: ${COLORS.buttonSecondaryBGActive};
      img {
        opacity: 1;
      }
    }

    &:disabled,
    [disabled] {
      background-color: ${hexToRGB(theme.colors.grey, 0.1)};
      border-color: ${hexToRGB(theme.colors.grey, 0.1)};
      color: ${hexToRGB(theme.colors.grey, 0.5)};
      pointer-events: none;

      &:hover {
        box-shadow: none;
        border-color: ${hexToRGB(theme.colors.grey, 0.1)};
      }
    }
  }

  .primaryWhiteOutlined {
    width: 223px;
    height: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    background: ${COLORS.white};
    border: 2px solid ${COLORS.whiteSea};
    border-radius: 100px;
    color: ${COLORS.blackLabel};

    &:hover {
      border-color: ${COLORS.whiteLight};
      background: ${COLORS.whiteSea};
      img {
        opacity: 1;
      }
    }
  }

  .mobileNavigation {
    align-items: center;
    border-radius: none;
    border: none;
    text-align: center;
    color: ${COLORS.urbanBaGLight};
    display: flex;
    align-items: center;
    background: ${COLORS.urbanBlack};
    transition: all 0.5s ease-out;
    
    img {
      opacity: 1;
    }

    &.active {
      background: ${COLORS.magenta};
      img {
        opacity: 1;
      }
    }
  }

`;


export const GoBackButtonWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   color: ${COLORS.white};

   button {
      width: 40px !important;
      height: 40px !important;
      margin-right: 20px;
      margin-bottom: 10px;
   }

   h1 {
      margin-left: 20px;
      margin-top: 9px;
      font-size: 14px;
      line-height: ${({ isMobile }) => isMobile ? '12px' : '28px'};
   }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleWrapper = styled.h1`
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: ${COLORS.blackLabel};
  margin-bottom: 25px;
`;

export const IconWrapper = styled.div`
  transform: rotate(180deg);
  margin-bottom: 3px;
`;