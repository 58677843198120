import { useTranslation } from 'react-i18next';
import { Rating } from '@material-ui/lab';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Tag from '../../Tags/Tag';
import { getCourseImage } from '../../../utils/courses/getCourseImage';
import { getCourseColor } from '../../../utils/courses/getCourseColor';
import { CursesSections } from '../../../types/common';

import {
   CoursesModifications,
} from '../../../types/courses/courses';

import {
   CourseWrapper,
   CourseImage,
   CourseTitle,
   CourseDescription,
   CourseFooter,
   CourseInfo,
} from './styled';
import CourseActions from './CourseActions';

const StyledRating = styled(Rating)({
   '& .MuiRating-iconFilled': {
      color: '#ff6d75',
   },
   '& .MuiRating-iconHover': {
      color: '#ff3d47',
   },
});

type Props = {
   courseModification: CoursesModifications;
   rating: number;
   section: CursesSections;
   button: string;
}

const Course = ({ courseModification, rating, section, button }: Props) => {
   const { t } = useTranslation();

   return <CourseWrapper>
      <CourseImage src={getCourseImage(courseModification)} alt="" />
      <CourseTitle>
         {t(`course.${courseModification}.title`)}
      </CourseTitle>
      <CourseDescription>
         {t(`course.${courseModification}.description`)}
      </CourseDescription>
      <CourseFooter>
         <CourseInfo color={getCourseColor(section)}>
            <Tag name={section} />
            <StyledRating
               readOnly
               name="read-only"
               defaultValue={2}
               getLabelText={(value: number) => `${value} Heart${value !== 1 ? 's' : ''}`}
               precision={rating}
               icon={<FavoriteIcon fontSize="small" />}
               emptyIcon={<FavoriteBorderIcon fontSize="small" />}
            />
         </CourseInfo>
         <CourseActions courseModification={courseModification} button={button} />
      </CourseFooter>
   </CourseWrapper>
}

export default Course;