
import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

export const RestoreWrapper = styled.div<{isMobile: boolean}>`
   border-radius: 10px;
   margin-right: 20px;
   cursor: pointer;
   display: flex;
   height: ${({ isMobile }) => isMobile ? '145px' : '245px'};
   margin-bottom: 0px;
   position: relative;
   width: 320px;
   margin-bottom: 20px;
`;

export const TagsWrapper = styled.div`
   display: flex;
   flex-wrap: wrap;
   margin-top: 20px;
`;

export const RestoreImage = styled.img<{isMobile: boolean}>`
   height: ${({ isMobile }) => isMobile ? '145px' : '245px'};
   border-radius: 10px;
   position: absolute;
   overflow: hidden;
   width: 320px;
   z-index: 0;
`;

export const RestoreContent = styled.div<{isMobile: boolean}>`
   top: ${({ isMobile }) => isMobile ? '20px' : '70px'};
   margin-left: 20px;
   z-index: 2;
`;

export const RestoreBottom = styled.div<{ isMobile: boolean }>`
   margin-top: ${({ isMobile }) => isMobile ? '20px' : '40px'};
   max-width: 300px;
   color: ${COLORS.white};

   h1 {
      font-size: 20px;
      line-height: ${({ isMobile }) => isMobile ? '20px' : '34px'};
   }

   p {
   font-weight: 500;
   font-size: 12px;
   line-height: 130%;
   opacity: 0.5;
   width: 180px;
   white-space: nowrap;
   overflow: hidden !important;
   text-overflow: ellipsis;
   
   /* Add these properties to limit text to 3 lines */
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   white-space: normal; /* Allow wrapping to new lines */
}
`;


export const KidsWrapper = styled.div`
   border-radius: 10px;
   margin-right: 30px;
   cursor: pointer;
   height: 245px;
   display: flex;
   margin-bottom: 20px;
   position: relative;
   
`;

export const KidsImage = styled.img`
   width: 100%;
   height: 100%;
   width: 285px;
   height: 245px;
   object-fit: cover;
   border-radius: 10px;
   position: absolute;
`;

export const KidsContent = styled.div`
   top: 70px;
   margin-left: 20px;
   z-index: 2;
`;

export const KidsBottom = styled.div`
   margin-top: 70px;
   max-width: 250px;
   color: ${COLORS.white};
   z-index: 2;

   h1 {
      font-size: 20px;
      z-index: 2;
   }

   p {
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      opacity: 0.5;
      display: inline-block;
      width: 180px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      z-index: 2;

   }
`;