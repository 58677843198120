import { useState } from 'react';
import GradeIcon from '@mui/icons-material/Grade';
import { useTranslation } from 'react-i18next';

import { SECTIONS, COURSES } from '../../../constants/courses';
import { CursesSections } from '../../../types/common';
import MaterialModal from '../Modal/MaterialModal';
import { COLORS } from '../../../constants/colors';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import InvoiceSteps from '../../InvoiceSteps/InvoiceSteps';
import { CoursesModifications } from '../../../types/courses/courses';

import { StarTooltipWrapper, StarTooltipHolder } from './styled';

const BookRequestTooltip = ({ bookModification, section }: { bookModification: CoursesModifications, section: CursesSections }) => {
   const { t } = useTranslation();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const { isMobile } = useMediaQuery();

   const handleOpenModal = () => {
      setIsModalOpen(!isModalOpen);
   }

   return (
      <>
         <StarTooltipHolder isMobile={isMobile} onClick={handleOpenModal}>
            <StarTooltipWrapper isMobile={isMobile}>
               <GradeIcon />
            </StarTooltipWrapper>
         </StarTooltipHolder>
         <MaterialModal
            modification={section}
            open={isModalOpen}
            handleClose={handleOpenModal}
            bgcolor={COLORS.courseCardBackground}
            title={t(`directions.${SECTIONS.youth}.book`)}
            width={400}
            height={500}
         >
            <InvoiceSteps
               modification={section}
               handleCloseModal={handleOpenModal}
               course={bookModification}
               invoiceDescription={t(`course.${bookModification}.invoiceDescription`)}
            />
         </MaterialModal>
      </>
   );
};

export default BookRequestTooltip;