import Dot from '../../../assets/global/btn-dot.svg';
import { SVGIcon } from '../../../utils/SVGIcon';
import { ReactNode } from 'react';

import { ButtonWrapper } from './styled';
import { colors } from '../../../styles/theme';
import { Link } from '@mui/material';
import { CursesSections } from 'types/common';

export type ButtonModifiers = 
| 'primary'
| 'circle'
| 'secondaryOutlined'
| 'mobileNavigation'
| 'primaryOutlined'
| 'primaryWhiteOutlined'
| 'transparent'

export type ButtonProps = {
  onClick?: (e?:any) => void;
  modifier?: ButtonModifiers;
  href?: string;
  className?: string;
  disabled?: boolean;
  dot?: boolean;
  icon?: JSX.Element;
  fillIcon?: keyof typeof colors;
  widthIcon?: number;
  heightIcon?: number;
  blank?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  children?: ReactNode;
  active?: boolean;
  color?: CursesSections;
  value?: string;
};

export const Button = ({
  onClick,
  children,
  modifier = 'primary',
  href,
  disabled,
  dot,
  className,
  icon,
  fillIcon,
  widthIcon,
  heightIcon,
  blank,
  htmlType = 'submit',
  loading,
  active = false,
  value,
  color,
}: ButtonProps) => {
  return (
    <ButtonWrapper>
      {href ? (
        <Link href={href}>
          <a className={`${modifier} ${className}`} target={blank ? '_blank' : '_self'}>
            {children}
            {icon ? (
              <div className='icon'>
                <SVGIcon fill={fillIcon} width={widthIcon} height={heightIcon} icon={icon} />
              </div>
            ) : null}
            {loading ? '...' : ''}
          </a>
        </Link>
      ) : (
        <button
          type={htmlType}
          className={`${modifier} ${className} ${active ? 'active' : ''} color-${color}`}
          onClick={onClick}
          disabled={disabled}
          value={value}
        >
          {children}
          {icon ? (
            <div className='icon'>
              <SVGIcon fill={fillIcon} width={widthIcon} height={heightIcon} icon={icon} />
            </div>
          ) : null}
          {loading ? '...' : ''}
          {dot ? <Dot className='dot' /> : null}
        </button>
      )}
    </ButtonWrapper>
  );
};
