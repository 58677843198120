import {
   Link
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../components/UI/Button';
import mainIcon from '../../../assets/navigation/home.svg';
import coursesIcon from '../../../assets/navigation/courses.svg';
import settingsIcon from '../../../assets/navigation/settings.svg';
import cabinetIcon from '../../../assets/navigation/cabinet.svg';
import backToCabinetIcon from '../../../assets/navigation/back-to-cabinet.svg';

import { changeActiveTab, setCurrentCourse } from '../../../store/actions/appActions';
import { ActiveTabs } from '../../../store/reducers/types';
import { selectActiveTab } from '../../../store/selectors/navigation';
import { selectUserProfile } from '../../../store/selectors/user';
import { getCourseVideo } from '../../../utils/courses/getCourseVideo';
import { selectCurrentCourse } from '../../../store/selectors/courses';
import { COURSES } from '../../../constants/courses';

import { OneElementWrapper, NavigationItemsWrapper, TagWrapper, NavButtonsWrapper } from './styled';


export const NavigationItems = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const activeTab = useSelector(selectActiveTab)
   const user = useSelector(selectUserProfile)
   const currentCourse = useSelector(selectCurrentCourse);
   const courseVideoUrl = getCourseVideo(currentCourse);
   const courseNotSelected = currentCourse === COURSES.prepareToSuccess ||
   currentCourse === COURSES.fiveDirections ||
   currentCourse === COURSES.relationshipGod ||
   currentCourse === COURSES.familyWithGod ||
   currentCourse === COURSES.creativeRestoration ||
   currentCourse === COURSES.bibleKidsLessons ||
   currentCourse === 'noSelected' ||
   courseVideoUrl === 'notSelected'

   const handleUnsetSelectedCourse = () => {
      dispatch(setCurrentCourse({ courseModification: 'noSelected' }))
   }

   const activeTabSwitcher = (activeTab: ActiveTabs) => {
      dispatch(changeActiveTab({ activeTab }))
   }

   return (
      <NavigationItemsWrapper>
         <TagWrapper>
            #{t('navigation.learning')}
         </TagWrapper>
         <NavButtonsWrapper>
            <OneElementWrapper>
               <Link to='/'><Button
                  modifier='secondaryOutlined'
                  onClick={() => activeTabSwitcher('main')}
                  active={activeTab === 'main'}
                  icon={<img src={mainIcon} />}
               >{t('navigation.tabs.main')}</Button></Link>
            </OneElementWrapper>
            <OneElementWrapper>
               <Link to='/courses'> <Button
                  onClick={() => activeTabSwitcher('courses')}
                  modifier='secondaryOutlined'
                  active={activeTab === 'courses'}
                  icon={<img src={coursesIcon} />}
               >{t('navigation.tabs.courses')}</Button> </Link>
            </OneElementWrapper>
            <OneElementWrapper>
               {activeTab === 'cabinet'
                  ? <Button
                     modifier='secondaryOutlined'
                     active={true}
                     onClick={handleUnsetSelectedCourse}
                     icon={courseNotSelected ? <img src={cabinetIcon} /> : <img src={backToCabinetIcon} />}
                  >{courseNotSelected ? t('navigation.tabs.cabinet') : t('navigation.tabs.inCabinet')}</Button>
                  : <Link to={'/cabinet'}> <Button
                     onClick={() => activeTabSwitcher('cabinet')}
                     modifier='secondaryOutlined'
                     active={false}
                     icon={<img src={cabinetIcon} />}
                  >{t('navigation.tabs.cabinet')}</Button> </Link>}
            </OneElementWrapper>
            {user && <OneElementWrapper>
               <Link to='/settings'> <Button
                  onClick={() => activeTabSwitcher('settings')}
                  modifier='secondaryOutlined'
                  active={activeTab === 'settings'}
                  icon={<img src={settingsIcon} />}
               >{t('navigation.tabs.settings')}</Button> </Link>
            </OneElementWrapper>}
         </NavButtonsWrapper>
      </NavigationItemsWrapper>

   );
};